<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'ManageDrivers'"
      :ActiveMenu="'List'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        :pageheaderTitle="`Driver Profile - `+ firstname.toUpperCase() + ` ` + surname.toUpperCase()"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Drivers"
        breadcrumbitem3="Profile"
      />

      <!-- end page-header -->
      <div class="row">
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-card"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">ASSIGNED TAXI</div>
              <div class="stats-number">{{assignRegNo || 'N/A'}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-hourglass"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">STATUS</div>
              <div class="stats-number">{{accountStatus || 'Pending'}}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
        <!-- begin col-3 -->
        <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-logo-usd"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">OUTSTANDING BALANCE</div>
              <div class="stats-number">{{availableBalance | newcurrencywithSymbol }}</div>
            </div>
          </div>
        </div>
        <!-- end col-3 -->
      </div>
      <br />
      <!-- begin #Main Body Template -->
      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-inverse">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>
              <h4 class="panel-title">Driver Profile</h4>
            </div>
            <!-- end panel-heading -->
            <!-- begin panel-body -->
            <div class="panel-body">
              <div class="row">
                <!-- begin col-6 -->

                <div class="col-lg-6">
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Title</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="title_name" />
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Gender</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="fk_gender_name" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Firstname</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="firstname" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Surname</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="surname" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">ID Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="step3_tara"
                        name="step3_tara"
                        disabled
                        class="form-control"
                        v-model="id_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Passport Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="step3_Registration_Number "
                        name="step3_Registration_Number"
                        disabled
                        class="form-control"
                        v-model="passport_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Country</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        id="step_3licence_expiry_date"
                        name="step_3licence_expiry_date"
                        disabled
                        class="form-control"
                        v-model="country_name"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Date of Birth</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="date_of_birth | formatToShortDate"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Mobile Number</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="mobilenumber" />
                    </div>
                  </div>
                  <!-- End form-group -->
                </div>

                <!-- Begin Second Row -->
                <div class="col-lg-6">
                  <!-- begin form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Existing Membership Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="existing_membership_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Driver Joined Date</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="taxi_driver_joined_date | formatToShortDate"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Driver License Number</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="driver_license_number"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">License Expiry Date</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="drivers_lisense_expirydate | formatToShortDate"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">PDP Number</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="pdp_number" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">PDP Expiry Date</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        :value="pdp_lisense_expirydate | formatToShortDate"
                      />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Telephone Number</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="telephone_number" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Fax Number</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="fax_number" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Email</label>
                    <div class="col-md-6">
                      <input type="text" disabled class="form-control" v-model="email_address" />
                    </div>
                  </div>
                  <!-- End form-group -->
                  <!-- begin form-group -->
                  <div class="form-group row m-b-10">
                    <label class="col-md-4 col-form-label text-md-right">Driver Status</label>
                    <div class="col-md-6">
                      <a
                        href="javascript:;"
                        id="driverStatus"
                        data-type="select"
                        data-pk="1"
                        data-value
                        data-title="Select driver status"
                      ></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col-12 -->
      <!-- End form-group -->
      <div class="btn-row">
        <div class="text-center">
          <router-link
            tag="button"
            class="btn btn-primary width-100 m-5"
            data-role="UTD"
            :to="{ name: 'editdriver', params: {id: taxi_driversid } }"
          >Edit</router-link>
          <router-link
            tag="button"
            class="btn btn-info width-100 m-5"
            data-role="UTD"
            :to="{ name: 'capturephotos', params: {id: taxi_driversid } }"
          >Photos</router-link>
          <router-link
            tag="button"
            class="btn btn-danger width-100 m-5"
            data-role="UTD"
            :to="{ name: 'assigndriver', params: {id: taxi_driversid} }"
          >Assign Driver</router-link>
        </div>
      </div>
      <br />

      <!-- begin nav-tabs -->
      <div class="panel panel-inverse panel-with-tabs" style="height: auto;">
        <!-- begin nav-tabs -->
        <!-- begin col-lg-12 -->
        <div class="col-lg-24">
          <!-- begin panel -->
          <div class="panel panel-inverse panel-with-tabs" data-sortable-id="ui-unlimited-tabs-1">
            <!-- begin panel-heading -->
            <div class="panel-heading p-0">
              <div class="panel-heading-btn m-r-10 m-t-10">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
              </div>
              <!-- begin nav-tabs -->
              <div class="tab-overflow">
                <ul class="nav nav-tabs nav-tabs-inverse">
                  <li class="nav-item prev-button">
                    <a href="javascript:;" data-click="prev-tab" class="nav-link text-success">
                      <i class="fa fa-arrow-left"></i>
                    </a>
                  </li>

                  <li class="nav-item">
                    <a href="#nav-tab-1" data-toggle="tab" class="nav-link active">Address</a>
                  </li>
                  <li class="nav-item">
                    <a href="#nav-tab-2" data-toggle="tab" class="nav-link">Mobile Numbers</a>
                  </li>
                  <li class="nav-item">
                    <a href="#nav-tab-3" data-toggle="tab" class="nav-link">Supporting Documents</a>
                  </li>
                  <li class="nav-item">
                    <a href="#nav-tab-4" data-toggle="tab" class="nav-link">Employment History</a>
                  </li>
                  <li class="nav-item">
                    <a href="#nav-tab-5" data-toggle="tab" class="nav-link">Timeline</a>
                  </li>
                  <li class="nav-item">
                    <a href="#nav-tab-6" data-toggle="tab" class="nav-link">Billings</a>
                  </li>
                  <li class="nav-item">
                    <a href="#nav-tab-7" data-toggle="tab" class="nav-link">Transactions</a>
                  </li>
                  <li class="nav-item">
                    <a href="#nav-tab-8" data-toggle="tab" class="nav-link">Receipt Disputes</a>
                  </li>
                  <li class="nav-item">
                    <a href="#nav-tab-9" data-toggle="tab" class="nav-link">Invoice Disputes</a>
                  </li>
                  <li class="nav-item next-button">
                    <a href="javascript:;" data-click="next-tab" class="nav-link text-success">
                      <i class="fa fa-arrow-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <!-- end nav-tabs -->
            </div>
            <!-- end panel-heading -->
            <!-- begin tab-content -->
            <div class="tab-content height-md">
              <!-- begin tab-pane -->
              <div class="tab-pane fade active show" id="nav-tab-1">
                <!-- begin panel-body -->
                <div class="panel-body">
                  <table id="data-table-titles" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th class="text-nowrap">Physical Address</th>
                        <th class="text-nowrap">Physical Address line 2</th>
                        <th class="text-nowrap">suburb</th>
                        <th class="text-nowrap">Postal Address</th>
                        <th class="text-nowrap">Postal Address line 2</th>
                        <th class="text-nowrap">suburb</th>
                        <th class="text-nowrap">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>{{physicaladdress || "N/A"}}</td>
                        <td>{{physicaladdress2 || "N/A"}}</td>
                        <td>{{physical_suburb || "N/A"}}</td>
                        <td>{{postaladdress || "N/A"}}</td>
                        <td>{{postaladdress2 || "N/A"}}</td>
                        <td>{{postal_suburb || "N/A"}}</td>
                        <td class="with-btn valign-middle" nowrap>
                          <button
                            type="button"
                            class="btn btn-sm btn-warning width-60 m-r-2 hide"
                            href="#addressModal"
                            data-toggle="modal"
                            data-role="RTD"
                          >View</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- End panel-body -->
              </div>
              <!-- end tab-pane -->
              <!-- begin tab-pane -->
              <div class="tab-pane fade" id="nav-tab-2">
                <!-- begin panel-body -->
                <div class="panel-body">
                  <table id="data-table-titles" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th class="text-nowrap">Name</th>
                        <th class="text-nowrap">Number</th>
                        <th class="text-nowrap">Status</th>
                        <th class="text-nowrap">Last Updated By</th>
                        <th class="text-nowrap">Action</th>
                      </tr>
                    </thead>

                    <tbody v-if="GETDRIVERNUMBERS.length > 0">
                      <tr
                        v-for="(item,index) in GETDRIVERNUMBERS"
                        v-bind:key="item.taxi_drivers_mobilenumbers_id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>{{item.taxi_driver_name}}</td>
                        <td>{{item._mobilenumber}}</td>
                        <td width="10%">
                          <span
                            class="badge f-s-12"
                            v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                            v-if="!item._updated_by"
                          >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                        </td>
                        <td>{{item._updateby_name || item._addedby_name}}</td>
                        <td class="with-btn valign-middle" nowrap>
                          <button
                            type="button"
                            class="btn btn-sm btn-warning width-60 m-r-2"
                            data-toggle="modal"
                            data-target="#ViewNumbersModal"
                            @click="setViewDetail(item)"
                            data-role="RTD"
                          >View</button>
                          <router-link
                            type="button"
                            class="btn btn-sm btn-primary width-60 m-r-2"
                            data-role="UTD"
                            :to="{ name: 'editdriver', params: {id: taxi_driversid } }"
                            :disabled="item._deleted_by"
                          >Edit</router-link>
                          <button
                            type="button"
                            class="btn btn-sm btn-success width-80"
                            v-show="item._deleted_by"
                            data-role="UTD"
                          >Restore</button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger width-80"
                            v-show="!item._deleted_by"
                            data-role="DTD"
                          >Delete</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- End panel-body -->
              </div>
              <!-- end tab-pane -->
              <!-- begin tab-pane -->
              <div class="tab-pane fade" id="nav-tab-3">
                <!-- Start panel-body -->
                <div class="panel-body">
                  <table id="data-table-titles" class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th width="1%" data-orderable="false"></th>
                        <th class="text-nowrap">Filename</th>
                        <th class="text-nowrap">Active</th>
                        <th class="text-nowrap">Last Updated By</th>
                        <th class="text-nowrap">Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="GETDRIVERSUPPORTINGDOCS.length > 0">
                      <tr
                        v-for="(item,index) in GETDRIVERSUPPORTINGDOCS"
                        v-bind:key="index"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                        <td>{{item._filename}}</td>
                        <td width="10%">
                          <span
                            class="badge f-s-12"
                            :class="{ 'badge-danger': item._deleted_by }"
                            v-if="item._deleted_by"
                          >Deleted</span>
                          <span
                            class="badge f-s-12"
                            v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                            v-if="!item._deleted_by"
                          >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                        </td>
                        <td>{{item._updateby_name || item._addedby_name}}</td>
                        <td class="with-btn valign-middle" nowrap>
                          <button
                          type="button"
                          class="btn btn-sm btn-success width-60 m-r-2"
                          @click="setViewDocs(context,item)"
                          >View</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- End panel-body -->
              </div>
              <!-- end tab-pane -->
              <!-- begin tab-pane -->
              <div class="tab-pane fade" data-role="RTD" id="nav-tab-4">
                <!-- begin timeline -->
                <ul class="timeline" v-if="GETDRIVEREMPLOYMENTHISTORY.length > 0">
                  <li
                    v-for="(item,index) in GETDRIVEREMPLOYMENTHISTORY"
                    v-bind:key="index"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <!-- begin timeline-time -->
                    <div class="timeline-time">
                      <span class="date">{{item._date_added | formatToHumanize}}</span>
                      <span class="time">{{item._date_added | formatToTime}}</span>
                    </div>
                    <!-- end timeline-time -->
                    <!-- begin timeline-icon -->
                    <div class="timeline-icon">
                      <a href="javascript:;">&nbsp;</a>
                    </div>
                    <!-- end timeline-icon -->
                    <!-- begin timeline-body -->
                    <div class="timeline-body">
                      <div class="timeline-header">
                        <span class="fa-stack fa-lg text-primary">
                          <i class="fas fa-address-card fa-2x"></i>
                        </span>
                        <span class="username">
                          <a href="javascript:;">{{item._addedby_name}}</a>
                          <small></small>
                        </span>
                        <span class="pull-right text-muted"></span>
                      </div>
                      <div class="timeline-content">
                        <p>{{item._comment}}</p>
                      </div>
                    </div>
                    <!-- end timeline-body -->
                  </li>
                </ul>
                <!-- end timeline -->
              </div>
              <!-- end tab-pane -->
              <!-- begin tab-pane -->
              <div class="tab-pane fade" id="nav-tab-5">
                <!-- begin timeline -->
                <ul class="timeline" v-if="GETDRIVERTIMELINE.length > 0">
                  <li
                    v-for="(item,index) in GETDRIVERTIMELINE"
                    v-bind:key="index"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
                  >
                    <!-- begin timeline-time -->
                    <div class="timeline-time">
                      <span class="date">{{item._date_added | formatToHumanize}}</span>
                      <span class="time">{{item._date_added | formatToTime}}</span>
                    </div>
                    <!-- end timeline-time -->
                    <!-- begin timeline-icon -->
                    <div class="timeline-icon">
                      <a href="javascript:;">&nbsp;</a>
                    </div>
                    <!-- end timeline-icon -->
                    <!-- begin timeline-body -->
                    <div class="timeline-body">
                      <div class="timeline-header">
                        <span class="fa-stack fa-lg text-primary">
                          <i class="fas fa-address-card fa-2x"></i>
                        </span>
                        <span class="username">
                          <a href="javascript:;">{{item._added_by_names}}</a>
                          <small></small>
                        </span>
                        <span class="pull-right text-muted"></span>
                      </div>
                      <div class="timeline-content">
                        <p>{{item._comment}}</p>
                      </div>
                    </div>
                    <!-- end timeline-body -->
                  </li>
                </ul>
                <!-- end timeline -->
              </div>
              <!-- end tab-pane -->
              <!-- begin tab-pane -->
              <div class="tab-pane fade" id="nav-tab-6">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Billing Date</th>
                        <th>Billing item</th>
                        <th>Amount</th>
                        <th>Collection Type</th>
                        <th>Last Billing Date</th>
                        <th>Next Billing Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody v-if="GETDRIVERBILLING.length > 0">
                      <tr
                        v-for="(item,index) in GETDRIVERBILLING"
                        :key="item._id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                        :value="item"
                      >
                        <td>{{item._billing_start_date | formatToShortDate}}</td>
                        <td>{{item._fk_billing_items_name}}</td>
                        <td>R {{item._amount | currency}}</td>
                        <td>{{item._fk_collection_frequency_name}}</td>
                        <td>{{item._last_bill_date | formatToShortDate}}</td>
                        <td>{{item._next_billing_date | formatToShortDate}}</td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger width-80"
                            @click="billingInfo(context,item)"
                            data-role="CBD"
                          >Change</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- end tab-pane -->
              <!-- begin tab-pane -->
              <!-- invoice Disputes -->
              <div class="tab-pane fade" id="nav-tab-7">
                <!-- transactions -->
                <!-- begin table-responsive -->
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th nowrap>REFERENCE</th>
                        <th nowrap>CHARGE</th>
                        <th nowrap>PAYMENT</th>
                        <th nowrap>BALANCE</th>
                        <th nowrap>ACTION</th>
                      </tr>
                    </thead>
                    <tbody v-if="GETDRIVERTRANSACTIONS1.length > 0">
                      <tr
                        v-for="(item,index) in GETDRIVERTRANSACTIONS1"
                        v-bind:key="index"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>
                          <span class="text-inverse">{{item._transaction_date | formatToShortDate }}</span>
                          <br />
                        </td>
                      <td v-if="item._receipts_id" >Payment Captured By {{item._captured_by_name }}</td>
                      <td v-if="item._invoice_id" >{{item._transaction_reference }}</td>
                        <td class="text-left text-green-darker">
                          {{ item._fk_invoice_id ? 'R': '' }}
                          {{ item._fk_invoice_id ? item._transaction_amount : '' | currency}}
                        </td>
                        <td class="text-left text-red-darker">
                          {{ item._fk_receipts_id ? 'R': '' }}
                          {{ item._fk_receipts_id ? item._transaction_amount : '' | currency}}
                        </td>
                        <td class="text-left">R{{item._remaning_balance | currency}}</td>
                        <td>
                          <button
                            type="button"
                            class="btn btn-sm btn-primary width-80"
                            @click="selectedReceipt(context,item)"
                            :disabled="$wait.is('dispute-' + item._id)"
                            data-role="IDD"
                          >
                            <v-wait v-bind:for="'dispute-' + item._id">
                              <template slot="waiting">
                                <i class="fas fa-spinner fa-spin"></i>
                              </template>
                              Dispute
                            </v-wait>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- end table-responsive -->
              </div>
              <!-- end tab-pane -->
              <!-- begin tab-pane -->
              <div class="tab-pane fade" id="nav-tab-8">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Date Actioned</th>
                        <th>Added By</th>
                        <th>Comments</th>
                        <th>Dispute Status</th>
                        <th>Media</th>
                        <th>Voicenotes</th>
                      </tr>
                    </thead>
                    <tbody v-if="GETDRIVERRECEIPTDISPUTES != 0">
                      <tr
                        v-for="(item,index) in GETDRIVERRECEIPTDISPUTES"
                        v-bind:key=" 'disputedreceipts-' + item._id"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>{{item._actioned_date | formatToShortDate}}</td>
                        <td>{{item._added_by_name}}</td>
                        <td>{{item._receiptdisputes_feedback_comments}}</td>
                        <td>
                          <span
                            class="badge f-s-12"
                            :class="{ 'badge-primary': item._fk_dispute_status_names == 'Active' }"
                            v-if="item._fk_dispute_status_names == 'Active'"
                          >{{item._fk_dispute_status_names}}</span>
                          <span
                            class="badge f-s-12"
                            :class="{ 'badge-grey': item._fk_dispute_status_names == 'Pending' }"
                            v-if="item._fk_dispute_status_names == 'Pending'"
                          >{{item._fk_dispute_status_names}}</span>
                          <span
                            class="badge f-s-12"
                            :class="{ 'badge-danger': item._fk_dispute_status_names == 'Declined' }"
                            v-if="item._fk_dispute_status_names == 'Declined'"
                          >{{item._fk_dispute_status_names}}</span>
                          <span
                            class="badge f-s-12"
                            :class="{ 'badge-success': item._fk_dispute_status_names == 'Resolved' }"
                            v-if="item._fk_dispute_status_names == 'Resolved'"
                          >{{item._fk_dispute_status_names}}</span>
                        </td>
                        <td
                          v-for=" media in item._receiptdisputes_feedback_media"
                          v-bind:key="'disputedmdiea-' + media.fileid"
                        >
                          <span
                            style="color: Dodgerblue; cursor: pointer;"
                            data-toggle4="tooltip-receipt-media"
                            :title="media.filename"
                            @click="downloadReceiptMedia(context, item._id, media.fileid)"
                          >
                            <i class="fas fa-file-pdf fa-fw fa-2x"></i>
                          </span>
                        </td>
                        <td
                          v-for=" item in item._receiptdisputes_feedback_voicenote"
                          v-bind:key="'disputedreceipt-' + item.fileid"
                        >
                          <span
                            style="color: red; cursor: pointer;"
                            data-toggle5="tooltip-receipt-voicenote"
                            :title="item.filename"
                          >
                            <i class="fas fa-microphone fa-fw fa-2x"></i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- end tab-pane -->
              <!-- begin tab-pane -->
              <div class="tab-pane fade" id="nav-tab-9">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Invoice Number</th>
                        <th>Added By</th>
                        <th>Dispute Status</th>
                        <th>Media</th>
                        <th>Voicenotes</th>
                      </tr>
                    </thead>
                    <tbody v-if="GETDRIVERINVOICEDISPUTES != 0">
                      <tr
                        v-for="(item,index) in GETDRIVERINVOICEDISPUTES"
                        v-bind:key="index"
                        :class="index & 1 ? 'odd gradeA' : 'even'"
                      >
                        <td>{{item._date_added | formatToShortDate}}</td>
                        <td>{{item._fk_invoice_number}}</td>
                        <td>{{item._added_by_names}}</td>
                        <td>
                          <span
                            class="badge f-s-12"
                            :class="{ 'badge-primary': item._fk_dispute_status_names == 'Active' }"
                            v-if="item._fk_dispute_status_names == 'Active'"
                          >{{item._fk_dispute_status_names}}</span>
                          <span
                            class="badge f-s-12"
                            :class="{ 'badge-grey': item._fk_dispute_status_names == 'Pending' }"
                            v-if="item._fk_dispute_status_names == 'Pending'"
                          >{{item._fk_dispute_status_names}}</span>
                          <span
                            class="badge f-s-12"
                            :class="{ 'badge-danger': item._fk_dispute_status_names == 'Declined' }"
                            v-if="item._fk_dispute_status_names == 'Declined'"
                          >{{item._fk_dispute_status_names}}</span>
                          <span
                            class="badge f-s-12"
                            :class="{ 'badge-success': item._fk_dispute_status_names == 'Resolved' }"
                            v-if="item._fk_dispute_status_names == 'Resolved'"
                          >{{item._fk_dispute_status_names}}</span>
                        </td>
                        <td
                          v-for=" media in item._invoicedisputes_feedback_media"
                          v-bind:key="media.fileid"
                        >
                          <span
                            style="color: Dodgerblue; cursor: pointer;"
                            data-toggle2="tooltip-invoice-media"
                            :title="media.filename"
                            @click="downloadInvoiceMedia(context, item._id, media.fileid)"
                          >
                            <i style="cursor: pointer;" class="fas fa-file-pdf fa-fw fa-2x"></i>
                          </span>
                        </td>
                        <td
                          v-for="(item) in item._invoicedisputes_feedback_voicenotes"
                          v-bind:key=" 'disputedinvoice-' +item.fileid"
                        >
                          <span
                            style="color: red; cursor: pointer;"
                            data-toggle3="tooltip-invoice-voicenote"
                            :title="item.filename"
                          >
                            <i style="cursor: pointer;" class="fas fa-microphone fa-fw fa-2x"></i>
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <!-- end tab-pane -->
            </div>
            <!-- end tab-content -->

            <!-- #addressModal -->
            
            <!-- #addressModal end -->

            <!-- Edit Modal HTML START-->

            <!-- Edit Modal HTML START-->

            <!-- #modal-message start -->
            <div class="modal fade" id="modal-dialog">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="text-center">
                    <h4 v-if="isInvoice" class="modal-title">Dispute Invoice</h4>
                    <h4 v-if="isReceipt" class="modal-title">Dispute Receipt</h4>
                  </div>
                  <div class="modal-body">
                    <div v-if="isInvoice" class="form-group row">
                      <label class="col-md-2 col-form-label">Comment:</label>
                      <div class="col-md-10">
                        <textarea
                          placeholder="Type your comments"
                          class="form-control m-b-10"
                          id="clip-textarea"
                          v-model="INVOICEDISPUTEADD.invoiceComments"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                    <div v-if="isReceipt" class="form-group row">
                      <label class="col-md-2 col-form-label">Comment:</label>
                      <div class="col-md-10">
                        <textarea
                          placeholder="Type your comments"
                          class="form-control m-b-10"
                          id="clipboard-textarea"
                          v-model="RECEIPTDISPUTEADD.receiptComments"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                    <br />
                    <div class="height-450" v-if="isInvoice">
                      <!-- begin nav-tabs -->
                      <ul class="nav nav-tabs">
                        <li class="nav-items">
                          <a href="#default-tab-1" data-toggle="tab" class="nav-link active">
                            <span class="d-sm-none">Tab 1</span>
                            <span class="d-sm-block d-none">Media</span>
                          </a>
                        </li>
                        <li class="nav-items">
                          <a href="#default-tab-2" data-toggle="tab" class="nav-link">
                            <span class="d-sm-none">Tab 2</span>
                            <span class="d-sm-block d-none">Voicenotes</span>
                          </a>
                        </li>
                      </ul>
                      <!-- end nav-tabs -->
                      <!-- begin tab-content -->
                      <div class="tab-content">
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade active show" id="default-tab-1">
                          <h3 class="m-t-10">Upload Documents</h3>
                          <DropZoneControl
                            :acceptedFiles="acceptedFiles"
                            :ClearData="true"
                            v-on:fileadded="fileadded"
                            :SessionID="invoiceDropZoneSessionID"
                          ></DropZoneControl>
                        </div>
                        <!-- end tab-pane -->
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade" id="default-tab-2">
                          <AudioVideoControl
                            :SessionID="invoiceVoiceNoteSessionID"
                            :ClearData="true"
                            v-on:fileadded="fileadded"
                          />
                        </div>
                        <!-- end tab-pane -->
                      </div>
                      <!-- end tab-content -->
                    </div>
                    <div class="height-450" v-if="isReceipt">
                      <!-- begin nav-pills -->
                      <ul class="nav nav-pills">
                        <li class="nav-items">
                          <a href="#nav-pills-tab-1" data-toggle="tab" class="nav-link active">
                            <span class="d-sm-none">Pills 1</span>
                            <span class="d-sm-block d-none">Media</span>
                          </a>
                        </li>
                        <li class="nav-items">
                          <a href="#nav-pills-tab-2" data-toggle="tab" class="nav-link">
                            <span class="d-sm-none">Pills 2</span>
                            <span class="d-sm-block d-none">Voicenotes</span>
                          </a>
                        </li>
                      </ul>
                      <!-- end nav-pills -->
                      <!-- begin tab-content -->
                      <div class="tab-content">
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade active show" id="nav-pills-tab-1">
                          <h3 class="m-t-10">Attach files</h3>
                          <DropZoneControl
                            :acceptedFiles="acceptedFiles"
                            :ClearData="true"
                            v-on:fileadded="fileadded"
                            :SessionID="receiptDropZoneSessionID"
                          ></DropZoneControl>
                        </div>
                        <!-- end tab-pane -->
                        <!-- begin tab-pane -->
                        <div class="tab-pane fade" id="nav-pills-tab-2">
                          <AudioVideoControl
                            :SessionID="invoiceVoiceNoteSessionID"
                            :ClearData="true"
                            v-on:fileadded="fileadded"
                          />
                        </div>
                        <!-- end tab-pane -->
                      </div>
                      <!-- end tab-content -->
                    </div>
                  </div>
                  <div class="text-center m-b-5">
                    <a
                      href="javascript:;"
                      class="btn btn-danger m-5 btn-lg"
                      data-dismiss="modal"
                    >Close</a>
                    <a
                      href="javascript:;"
                      class="btn btn-primary m-5 btn-lg"
                      data-dismiss="modal"
                      v-if="isReceipt"
                      @click="disputeReceipt(context,RECEIPTDISPUTEADD)"
                    >Submit</a>
                    <a
                      href="javascript:;"
                      class="btn btn-primary m-5 btn-lg"
                      data-dismiss="modal"
                      v-if="isInvoice"
                      @click="disputeInvoice(context, INVOICEDISPUTEADD)"
                    >Submit</a>
                  </div>
                </div>
              </div>
            </div>
            <!-- #modal-message end -->

            <!-- #modal-dialog -->
            <div class="modal fade" id="changeBilling">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="text-center">
                    <h4 class="modal-title">Change Driver billing</h4>
                  </div>
                  <div class="modal-body">
                    <!-- begin form-group -->
                    <div class="form-group row m-b-10">
                      <label class="col-md-3 col-form-label text-md-right">Amount</label>
                      <div class="col-md-6">
                        <input
                          type="number"
                          id="amount"
                          name="amount"
                          class="form-control"
                          v-model="CHANGEBILLING.amount"
                          placeholder="R0.00"
                        />
                      </div>
                    </div>
                    <!-- End form-group -->
                    <!-- begin form-group -->
                    <div class="form-group row m-b-10">
                      <label
                        class="col-md-3 text-nowrap col-form-label text-md-right"
                      >Billing End Date:</label>
                      <div class="date text-inverse col-md-7 pull-center">
                        <div
                          class="input-group date"
                          id="datepicker-billingEnd"
                          data-date-format="dd M yyyy"
                        >
                          <input type="text" class="form-control" placeholder="Billing End Date" />
                          <span class="input-group-addon">
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- end form group -->
                    <!-- begin form-group -->
                    <div class="form-group row m-b-10">
                      <label
                        class="col-md-3 text-nowrap col-form-label text-md-right"
                      >Next Billing Date:</label>
                      <div class="date text-inverse col-md-7 pull-center">
                        <div
                          class="input-group date"
                          id="datepicker-nextBilling"
                          data-date-format="dd M yyyy"
                        >
                          <input type="text" class="form-control" placeholder="Next Billing  Date" />
                          <span class="input-group-addon">
                            <i class="fa fa-calendar"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- end form group -->
                  </div>
                  <div class="modal-footer">
                    <div class="text-center">
                      <button
                        href="javascript:;"
                        class="btn btn-white m-5"
                        data-dismiss="modal"
                      >Close</button>
                      <button
                        @click="changeDriverBillingType(context,CHANGEBILLING)"
                        class="btn btn-primary m-5"
                      >Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- #modal-dialog end -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end panel-heading -->
      </div>
      <!-- end nav-tabs -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import AudioVideoControl from "@/components/common/AudioVideoControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("TaxiDrivers");

export default {
  name: "DriverProfile",
  data: function () {
    return {
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      transid: "",
      isOpen: false,
      isReceipt: false,
      isInvoice: false,
      acceptedFiles: "application/pdf",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      context: this,
      id: this.$route.params.id,
      GETTAXIDRIVER: [],
      comments: "",
      skip: 0,
      length: 20,
      RECEIPTDISPUTEADD: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        receiptid: "",
        receiptComments: "",
        voicenotesessionid: "",
        dropzoneid: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      INVOICEDISPUTEADD: {
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        invoiceid: "",
        invoiceComments: "",
        voicenotesessionid: "",
        dropzoneid: "",
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      ASSIGNVEHICLE: {
        taxi_driversid: this.$route.params.id,
        taxi_operatorid: 0,
        taxi_operator_vehicle: 0,
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      ASSIGNOPERATOR: {
        taxi_driversid: this.$route.params.id,
        taxi_operatorid: null,
        taxi_operator_vehicle: null,
        addedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      CHANGEBILLING: {
        taxidriverbillingid: null,
        taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        taxidriverid: null,
        billingitemsid: null,
        amount: null,
        collectionfrequencyid: null,
        collectionfrequencynumofdays: "",
        billingtypeid: null,
        billingtypecode: "",
        collectionsettingsid: null,
        billingstartdate: "",
        billingenddate: "",
        nextbillingdate: "",
        lastbilldate: "",
        updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      accountNumber: "",
      accountStatus: "",
      assignRegNo: "",
      availableBalance: null,
      title: "",
      invoiceDropZoneSessionID: this.$uuid.v4(),
      invoiceVoiceNoteSessionID: this.$uuid.v4(),
      receiptDropZoneSessionID: this.$uuid.v4(),
      receiptVoiceNoteSessionID: this.$uuid.v4(),
      DropZoneSessionID: this.$uuid.v4(),
      operator: {},
      taxi: {},
      checker: {},
      DRIVERPROFILE: {},
      driverTempStatus: "",
      canEditDriver: "",
      canDeleteDriver: "",
      canChangeDriverBilling: "",
      CanInsertDispute: "",
      tempStatus: null,
      title_name: "",
      fk_gender_name: "",
      firstname: "",
      surname: "",
      id_number: "",
      passport_number: "",
      country_name: "",
      date_of_birth: "",
      mobilenumber: "",
      existing_membership_number: "",
      taxi_driver_joined_date: "",
      driver_license_number: "",
      drivers_lisense_expirydate: "",
      pdp_number: "",
      pdp_lisense_expirydate: "",
      telephone_number: "",
      fax_number: "",
      email_address: "",
      taxi_driversid: 0,
      physicaladdress: "",
      physicaladdress2: "",
      physical_suburb: "",
      postaladdress: "",
      postaladdress2: "",
      postal_suburb: "",
      GETDRIVERSUPPORTINGDOCS: [],
      GETSELECTEDNUMBER: Object.assign(
        {},
        this.$store.getters["TaxiDrivers/GETTAXIDRIVEREDIT"]
      )[0],
    };
  },
  beforeMount: function () {
    // this.clearAll();
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    AudioVideoControl,
  },
  computed: {
    ...mapGetters({
      GETDRIVERNUMBERS: "GETDRIVERNUMBERS",
      GETDRIVEREMPLOYMENTHISTORY: "GETDRIVEREMPLOYMENTHISTORY",
      GETDRIVERTIMELINE: "GETDRIVERTIMELINE",
      GETDRIVERINVOICEDISPUTES: "GETDRIVERINVOICES",
      GETDRIVERRECEIPTDISPUTES: "GETDRIVERRECEIPTS",
      GETDRIVERTRANSACTIONS1: "GETDRIVERTRANSACTIONS",
      GETDRIVERSTATUS1: "GETDRIVERSTATUS",
      GETDRIVERBILLING: "GETDRIVERBILLINGS",
    }),
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    // getHistory: function() {
    //   return context.GETDRIVEREMPLOYMENTHISTORY;
    // }
  },
  watch: {},
  methods: {
    ...mapActions([
      "getall",
      "delete",
      "edit",
      "add",
      "getAllByTaxiAssID",
      "markAsDeleted",
      "clearAll",
      "restoreDeleted",
      "getDriverByIdNoPic",
      "updateDriverStatusRequest",
      "assignDriverVehicle",
      "getDriverBillingsById",
      "changeDriverBilling",
    ]),
    setViewDocs: (context, item) => {
      window.open(item._document, '_blank');
    },
    billingInfo: (context, item) => {
      console.log("ive been clicked!", item);
      $("#changeBilling").modal("show");
      if (item != 0 && item != null && item != {}) {
        context.CHANGEBILLING.taxidriverbillingid = item._id;
        context.CHANGEBILLING.taxidriverbillingtypeid = item._fk_billingtype_id;
        context.CHANGEBILLING.billingstartdate = item._billing_start_date;
        context.CHANGEBILLING.lastbilldate = item._last_bill_date;
        context.CHANGEBILLING.collectionfrequencynumofdays =
          item._collection_frequency_num_of_days;
        context.CHANGEBILLING.taxidriverid = item._fk_taxi_driver_id;
        context.CHANGEBILLING.billingitemsid = item._fk_billing_items_id;
        context.CHANGEBILLING.collectionfrequencyid =
          item._fk_collection_frequency_id;
        context.CHANGEBILLING.billingtypeid = item._fk_billingtype_id;
        context.CHANGEBILLING.billingtypecode = item._billingtype_code;
        context.CHANGEBILLING.collectionsettingsid =
          item._fk_collection_settings_id;
      }
    },
    driverStatusByid: (context) => {
      context.$store
        .dispatch("TaxiDrivers/driverStatusByAssId", {
          id: context.taxiassociationid,
          router: context.$router,
        })
        .then((data) => {
          console.log("driver status: ", data);

          const driverstatussource = data.map((element) => {
            return {
              value: element._driver_status_id,
              text: element._driver_status_name,
            };
          });
          console.log(
            "driverstatussource: " + JSON.stringify(driverstatussource)
          );

          const driverstatus = context.driverTempStatus;
          console.log("For function: ", driverstatus);
          initiateDriverStatus(driverstatussource, driverstatus);
        })
        .catch((err) => {
          console.log("driver status errors:", err);
        });
      //this.$emit('init')
    },

    driverEmploymentHistory: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getDriverEmploymentHistoryById", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("driverEmploymentHistory: ", data);
        })
        .catch((err) => {
          console.log(err);
        });
      //this.$emit('init')
    },

    driverTimeline: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getDriverTimelineById", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("driverTimeline: ", data);
        })
        .catch((err) => {
          console.log(err);
        });
      //this.$emit('init')
    },

    getDriverBillings: (context, id) => {
      
      context
        .getDriverBillingsById({
          id,
          skip: context.skip,
          len: context.length,
          router: context.$router,
        })
        .then((data) => {
          console.log("driver billings: ", data);
        })
        .catch((err) => {
          console.log("billings errrors: ", err);
        });
    },

    getDriverbyId: (context) => {
      context
        .getDriverByIdNoPic({
          id: context.id,
          router: context.$router,
        })
        .then((_rb) => {
          console.log("this is the drivers profile ", _rb[0]);

          context.GETTAXIDRIVER = _rb;

          const account = _rb[0]._account_number;
          const active = _rb[0]._accountstatus;

          context.assignRegNo = _rb[0]._assignedregnum;

          context.physicaladdress = _rb[0].physicaladdress;
          context.physicaladdress2 = _rb[0].physicaladdress2;
          context.physical_suburb = _rb[0].physical_suburb;
          context.postaladdress = _rb[0].postaladdress;
          context.postaladdress2 = _rb[0].postaladdress2;
          context.postal_suburb = _rb[0].postal_suburb;

          context.title_name = _rb[0].title_name;
          context.fk_gender_name = _rb[0].fk_gender_name;
          context.firstname = _rb[0].firstname;
          context.surname = _rb[0].surname;
          context.id_number = _rb[0].id_number;
          context.passport_number = _rb[0].passport_number;
          context.country_name = _rb[0].country_name;
          context.date_of_birth = _rb[0].date_of_birth;
          context.mobilenumber = _rb[0].mobilenumber;
          context.existing_membership_number =
            _rb[0].existing_membership_number;
          context.taxi_driver_joined_date = _rb[0].taxi_driver_joined_date;
          context.driver_license_number = _rb[0].driver_license_number;
          context.drivers_lisense_expirydate =
            _rb[0].drivers_lisense_expirydate;
          context.pdp_number = _rb[0].pdp_number;
          context.pdp_lisense_expirydate = _rb[0].pdp_lisense_expirydate;
          context.telephone_number = _rb[0].telephone_number;
          context.fax_number = _rb[0].fax_number;
          context.email_address = _rb[0].email_address;
          context.taxi_driversid = _rb[0].taxi_driversid;
          

          if (account != " ") {
            context.accountNumber = _rb[0]._account_number;
            context.accountStatus = _rb[0]._accountstatus;
            context.availableBalance = _rb[0]._available_balance;
            console.log("ive been hit: ");

            context.driverTransactions(context);
          } else {
            console.log("ive missed: ");
            context.accountNumber = "";
            context.accountStatus = "";
            context.availableBalance = 0;
          }

          context.driverStatusByid(context);

          if (context.ROLES) {
            context.ROLES.map((element) => {
              if (element._code == "UTD") {
                context.canEditDriver = element._code;
              }
              if (element._code == "DTD") {
                context.canDeleteDriver = element._code;
              }
              if (element._code == "CDB") {
                context.canChangeDriverBilling = element._code;
              }
            });
          }
          context.driverTempStatus = _rb[0].driver_status_name;
        });
    },

    driverTransactions: (context) => {
      
      if (
        context.accountNumber != "" &&
        context.accountNumber != null &&
        context.accountNumber != undefined
      ) {
        context.$store
          .dispatch("TaxiDrivers/getDriverTransactionsById", {
            taxiassociationid: context.taxiassociationid,
            accountNumber: context.accountNumber,
            skip: context.skip,
            length: context.length,
            router: context.$router,
          })
          .then((data) => {
            console.log("transactions: ", data);
          })
          .catch((err) => {
            console.log("transaction errors: ", err);
          });
        //this.$emit('init')
      } else {
        context.accountNumber = "";
      }
    },

    driverMobileNumbers: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getDriverNumbersById", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          console.log("mobile numbers: ", data);
        })
        .catch((err) => {
          console.log(err);
        });
      //this.$emit('init')
    },

    driverSupportingDocs: (context, id) => {
      context.$store
        .dispatch("TaxiDrivers/getDriverDocsById", {
          _dataObj: {
            id,
            router: context.$router,
          },
        })
        .then((data) => {
          context.GETDRIVERSUPPORTINGDOCS = data;
        })
        .catch((err) => {
          console.log(err);
        });
      //this.$emit('init')
    },

    // show: function(context, increment) {
    //   if (increment) context.skip += 8;
    //   else {
    //     if (context.skip > 0) context.skip -= 8;
    //   }

    // },

    driverDisputedInvoices: (context, id) => {
      
      context.$store
        .dispatch("TaxiDrivers/getDriverDisputedInvoicesById", {
          id,
          router: context.$router,
        })
        .then((data) => {
          console.log("invoice Disputes: ", data);
        })
        .catch((err) => {
          console.log("invoice Disputes errors: ", err);
        });
      //this.$emit('init')
    },

    driverDisputedReceipts: (context, id) => {
      //
      context.$store
        .dispatch("TaxiDrivers/getDriverDisputedReceiptsById", {
          id,
          router: context.$router,
        })
        .then((data) => {
          console.log("receipt Disputes: ", data);
        })
        .catch((err) => {
          console.log("receipt Disputes errors: ", err);
        });
      //this.$emit('init')
    },

    fileadded: function (data) {
      console.log("Header:fileadded");
      console.log(data);
      //this.$emit('init')

      const context = $("#content").data("context");
      context.GETUPLOADEDFILES = context.$store.getters["fileupload/GETALL"];
    },

    selectedReceipt: (context, item) => {
      console.log(item);
      alert("ive been clicked");
      context.transid = item._id;
      if (
        item._fk_receipts_id != null &&
        item._fk_receipts_id != undefined &&
        item._fk_receipts_id != ""
      ) {
        let receipt = item._fk_receipts_id;
        context.RECEIPTDISPUTEADD.receiptid = receipt;
        console.log("selectedreceipt ", context.RECEIPTDISPUTEADD.receiptid);
        context.isReceipt = true;
        context.isInvoice = false;
        $("#modal-dialog").modal("show");
      } else if (
        item._fk_invoice_id != null &&
        item._fk_invoice_id != undefined &&
        item._fk_invoice_id != ""
      ) {
        let invoice = item._fk_invoice_id;
        context.INVOICEDISPUTEADD.invoiceid = invoice;
        console.log("selectedinvoice ", context.INVOICEDISPUTEADD.invoiceid);
        context.isInvoice = true;
        context.isReceipt = false;
        $("#modal-dialog").modal("show");
      } else {
        alert("no receipt or invoice found");
      }
    },

    changeDriverBillingType: (context, _addModel) => {
      
      context.CHANGEBILLING.billingstartdate = context.$Toolkit.ConvertToServerDate(
        context.CHANGEBILLING.billingstartdate
      );

      if (context.CHANGEBILLING.lastbilldate != null) {
        context.CHANGEBILLING.lastbilldate = context.$Toolkit.ConvertToServerDate(
          context.CHANGEBILLING.lastbilldate
        );
      } else {
        var newdate = new Date();
        context.CHANGEBILLING.lastbilldate = context.$Toolkit.ConvertToServerDate(
          newdate
        );
      }

      const billingEndDate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-billingEnd").find("input").val()
      );

      const nextBillingDate = context.$Toolkit.ConvertToServerDate(
        $("#datepicker-nextBilling").find("input").val()
      );

      context.CHANGEBILLING.billingenddate = billingEndDate;
      context.CHANGEBILLING.nextbillingdate = nextBillingDate;

      context.$Notify.YesNo(false, { context, _addModel }).then((value) => {
        
        if (value) {
          value.router = value.context.$router;
          value.context.$store
            .dispatch("TaxiDrivers/changeDriverBilling", {
              addModel: value._addModel,
              router: value.router,
            })
            .then((data) => {
              value.context.CHANGEBILLING.amount = null;
              $("#changeBilling").modal("hide");
              value.context.$Notify.Success("Billing change requested", data);
            })
            .catch((error) => {
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },

    downloadReceiptMedia: (context, receiptsdisputesid, fileid) => {
      context.$store
        .dispatch("TaxiDrivers/downloadMedia", {
          receiptsdisputesid,
          fileid,
          router: context.$router,
        })
        .then((_rb) => {
          console.log(_rb[0].fn_receipts_disputes_get_attached[0].filename);
          context.downloadItem(
            _rb[0].fn_receipts_disputes_get_attached[0].filename,
            _rb[0].fn_receipts_disputes_get_attached[0].data,
            _rb[0].fn_receipts_disputes_get_attached[0].mimetype
          );
        })
        .catch((err) => {
          console.log(err);
        });
      //this.$emit('init')
    },
    downloadInvoiceMedia: (context, invoicedisputesid, fileid) => {
      context.$store
        .dispatch("invoices/downloadMedia", {
          invoicedisputesid,
          fileid,
          router: context.$router,
        })
        .then((_rb) => {
          console.log(_rb[0].fn_invoice_disputes_get_attached[0].filename);
          context.downloadItem(
            _rb[0].fn_invoice_disputes_get_attached[0].filename,
            _rb[0].fn_invoice_disputes_get_attached[0].data,
            _rb[0].fn_invoice_disputes_get_attached[0].mimetype
          );
        })
        .catch((err) => {
          console.log(err);
        });
      //this.$emit('init')
    },

    downloadItem(filename, data, type) {
      const linkSource = `data:application/pdf;base64,${data}`;
      const downloadLink = document.createElement("a");
      const fileName = filename;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    },

    disputeReceipt: (context, RECEIPTDISPUTEADD) => {
      context.RECEIPTDISPUTEADD.dropzoneid = context.receiptDropZoneSessionID;
      context.RECEIPTDISPUTEADD.voicenotesessionid =
        context.receiptVoiceNoteSessionID;

      context.RECEIPTDISPUTEADD.receiptComments = document.getElementById(
        "clipboard-textarea"
      ).value;

      context.$Notify
        .YesNo(false, { context, RECEIPTDISPUTEADD })
        .then((value) => {
          if (value) {
            value.context.$wait.start("dispute-" + context.transid);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("invoices/createReceiptDisputeRequest", {
                router: value.router,
                _addModel: {
                  taxi_association_id:
                    context.RECEIPTDISPUTEADD.taxiassociationid,
                  receipt_id: context.RECEIPTDISPUTEADD.receiptid,
                  comments: context.RECEIPTDISPUTEADD.receiptComments,
                  voicenotesessionid:
                    context.RECEIPTDISPUTEADD.voicenotesessionid,
                  dropzoneid: context.receiptDropZoneSessionID,
                  addedby: context.updatedby,
                },
              })
              .then(() => {
                context.RECEIPTDISPUTEADD.receiptComments = "";
                context.RECEIPTDISPUTEADD.voicenotesessionid = "";
                value.context.$wait.end("dispute-" + context.transid);
                value.context.$Notify.Success("Dispute Request added!", "");
              })
              .catch((error) => {
                value.context.$wait.end("dispute-" + context.transid);

                value.context.$Notify.Error(error.message, "");
              })
              .finally(() => {});
          }
        });
    },

    disputeInvoice: (context, INVOICEDISPUTEADD) => {
      context.INVOICEDISPUTEADD.dropzoneid = context.DropZoneSessionID;
      context.INVOICEDISPUTEADD.voicenotesessionid =
        context.invoiceDropZoneSessionID;

      context.INVOICEDISPUTEADD.invoiceComments = document.getElementById(
        "clip-textarea"
      ).value;

      context.$Notify
        .YesNo(false, { context, INVOICEDISPUTEADD })
        .then((value) => {
          if (value) {
            value.context.$wait.start("dispute-" + context.transid);
            value.router = value.context.$router;
            value.context.$store
              .dispatch("invoices/createInvoiceDisputeRequest", {
                router: value.router,
                _addModel: {
                  taxi_association_id:
                    context.INVOICEDISPUTEADD.taxiassociationid,
                  invoice_id: context.INVOICEDISPUTEADD.invoiceid,
                  comments: context.INVOICEDISPUTEADD.invoiceComments,
                  voicenotesessionid:
                    context.INVOICEDISPUTEADD.voicenotesessionid,
                  dropzoneid: context.INVOICEDISPUTEADD.dropzoneid,
                  addedby: context.updatedby,
                },
              })
              .then(() => {
                context.INVOICEDISPUTEADD.invoiceComments = "";
                context.DropZoneSessionID = "";
                value.context.$wait.end("dispute-" + context.transid);
                value.context.$Notify.Success("Dispute Request added!", "");
              })
              .catch((error) => {
                value.context.$wait.end("dispute-" + context.transid);

                value.context.$Notify.Error(error.data[0].message, "");
              })
              .finally(() => {});
          }
        });
    },

    clearDrivers: (context) => {
      context.clearAll();
    },

    setViewDetail(val) {
      console.log(val);
      this.GETSELECTEDNUMBER = Object.assign({}, val);
    },

    getAddress: (context, payload) => {
      console.log(payload);
      alert("ive been clicked");
    },

    FILTERBYROLE: (context) => {
      $(".byrole").each(function () {
        if (
          context.USERROLES.length > 0 &&
          context.USERROLES.filter(
            (item) => item._code === $(this).attr("data-role")
          ).length > 0
        )
          $(this).removeClass("hide");
      });
    },

    // updateSupportingDocs: context => {
    //   context.$Notify.YesNo(false, { context }).then(value => {
    //     const dropzone_uploads = context.$store.getters["fileupload/GETALL"];
    //     const fileuploaded = [];

    //     if (dropzone_uploads.length > 0) {
    //       var failedvalidation = false;
    //       dropzone_uploads.forEach(element => {
    //         if (element.customType === 0) {
    //           failedvalidation = true;
    //           return;
    //         }
    //         fileuploaded.push({
    //           customType: element.customType,
    //           fileId: element.fileId
    //         });
    //       });
    //       if (failedvalidation) {
    //         value.context.$Notify.Error("Please Select Document Type.", "");
    //         return;
    //       }
    //     }
    //     value.dropzone_files = fileuploaded;

    //     if (value) {
    //       value.context.$wait.start("add");
    //       value.router = value.context.$router;
    //       value.context.$store
    //         .dispatch("TaxiDrivers/updateDriverSupportingDocs", {
    //           router: value.router,
    //           addModel: {
    //             taxi_drivers_id: context.id,
    //             dropzoneid: context.DropZoneSessionID,
    //             dropzonepayload: value.dropzone_files,
    //             addedby: context.updatedby
    //           }
    //         })
    //         .then(() => {
    //           value.context.$wait.end("add");
    //           value.context.$Notify.Success("Documents Uploaded!", "");
    //         })
    //         .catch(error => {
    //           value.context.$wait.end("add");

    //           value.context.$Notify.Error(error.data[0].message, "");
    //         })
    //         .finally(() => {});
    //     }
    //   });
    // },
    // addReceiptDispute: (
    //   context,
    //   receiptid,
    //   invoicestatus,
    //   comments,
    //   voicenote,
    //   media
    // ) => {
    //   context.$Notify
    //     .YesNo(false, {
    //       context,
    //       receiptid,
    //       invoicestatus,
    //       comments,
    //       voicenote,
    //       media
    //     })
    //     .then(value => {
    //       context.RECEIPTDISPUTEADD.dropzoneid = context.receiptDropZoneSessionID;
    //   context.RECEIPTDISPUTEADD.voicenotesessionid =
    //     context.receiptVoiceNoteSessionID;

    //   context.RECEIPTDISPUTEADD.receiptComments = document.getElementById(
    //     "clip-textarea"
    //   ).value;

    //       if (value) {
    //         
    //         value.context.$wait.start("add");
    //         value.router = value.context.$router;
    //         value.context.$store
    //           .dispatch("receiptdisputes/createInvoiceDispute", {
    //             router: value.router,
    //             _addDispute: {
    //               taxi_association_id: context.taxiassociationid,
    //               receiptid,
    //               invoicestatus,
    //               comments,
    //               voicenote,
    //               media,
    //               addedby: context.updatedby
    //             }
    //           })
    //           .then(() => {
    //             value.context.$wait.end("add");
    //             value.context.$Notify.Success("Documents Uploaded!", "");
    //           })
    //           .catch(error => {
    //             value.context.$wait.end("add");

    //             value.context.$Notify.Error(error.data[0].message, "");

    //           })
    //           .finally(() => {});
    //       }
    //     });
    // },
    // Delete: (context, id, deletedby) => {
    //   context.$Notify.YesNo(false, { context, id, deletedby }).then(value => {
    //     if (value) {
    //       value.context.$wait.is("delete-" + value.id);

    //       value.context
    //         .markAsDeleted({
    //           router: value.context.$router,
    //           id: value.id,
    //           deletedby: value.deletedby
    //         })
    //         .then(() => {
    //           value.context.$wait.end("delete-" + value.id);
    //           value.context.$Notify.Success("Successfully Deleted!", "");
    //         })
    //         .catch(error => {
    //           value.context.$wait.end("delete-" + value.id);
    //           value.context.$Notify.Error(error.data[0].message, "");
    //         })
    //         .finally(() => {});
    //     }
    //   });
    // },
    // Restore: (context, id, restoredby) => {
    //   context.$Notify.YesNo(false, { context, id, restoredby }).then(value => {
    //     if (value) {
    //       value.context.$wait.is("restore-" + value.id);

    //       value.context
    //         .restoreDeleted({
    //           router: value.context.$router,
    //           id: value.id,
    //           restoredby: value.restoredby
    //         })
    //         .then(() => {
    //           value.context.$wait.end("restore-" + value.id);
    //           value.context.$Notify.Success("Successfully Deleted!", "");
    //         })
    //         .catch(error => {
    //           value.context.$wait.end("restore-" + value.id);
    //           value.context.$Notify.Error(error.data[0].message, "");
    //         })
    //         .finally(() => {});
    //     }
    //   });
    // },

    // getbyid: (context, id) => {
    //   context
    //     .getDriverById({
    //       id,
    //       router: context.$router
    //     })
    //     .then(() => {
    //
    //     });
    // },
  },
  mounted: function () {
    this.getDriverbyId(this.context);
    this.driverMobileNumbers(this.context, this.id);
    this.driverSupportingDocs(this.context, this.id);

    this.driverEmploymentHistory(this.context, this.id);
    this.driverTimeline(this.context, this.id);
    this.getDriverBillings(this.context, this.id);

    // this.show(this.context, true);
    this.driverDisputedInvoices(this.context, this.id);
    this.driverDisputedReceipts(this.context, this.id);

    App.init();

    $("#datepicker-billingEnd").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    $("#datepicker-nextBilling").datepicker({
      todayHighlight: true,
      autoclose: true,
    });

    $(".byrole").addClass("hide");
    this.FILTERBYROLE(this.context);
    window.PushManager.listenToIncomingMessages(this.context);

    $("#driverStatus").data("context", this.context);

    // $("#sex").editable({
    //   prepend: "not selected",
    //   source: [{ value: 1, text: "Male" }, { value: 2, text: "Female" }],
    //   display: function(value, sourceData) {
    //     var icons = {
    //         "": "",
    //         1: '<i class="fa fa-male m-r-5"></i>',
    //         2: '<i class="fa fa-female m-r-5"></i>'
    //       },
    //       elem = $.grep(sourceData, function(o) {
    //         return o.value == value;
    //       });

    //     if (elem.length) {
    //       $(this)
    //         .text(elem[0].text)
    //         .prepend(icons[value]);
    //     } else {
    //       $(this).empty();
    //     }
    //   }
    // });

    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle2="tooltip-invoice-media"]').tooltip();
    $('[data-toggle3="tooltip-invoice-voicenote"]').tooltip();
    $('[data-toggle4="tooltip-receipt-media"]').tooltip();
    $('[data-toggle5="tooltip-receipt-voicenote"]').tooltip();
  },
  beforeDestroy: function () {},
};
var initiateDriverStatus = function (driverstatussource, driverstatus) {
  const context = $("#driverStatus").data("context");
  $("#driverStatus").editable({
    prepend: driverstatus,
    source: driverstatussource,
    url: null,
    mode: "inline",
    success: function (response, newValue) {
      console.log(this.prepend);
      const context = $("#driverStatus").data("context");
      context.$Notify.YesNo(false, { context, newValue }).then((value) => {
        if (value) {
          if (context.canChangeDriverBilling !== "CDB") {
            return value.context.$Notify.Error(
              "Sorry, You Do Not Have Permission For This Action."
            );
          } else {
            value.context.$wait.start("add");
            value.router = value.context.$router;
            value.context
              .updateDriverStatusRequest({
                router: value.router,
                addModel: {
                  taxiassociationid: context.taxiassociationid,
                  taxidriverid: context.id,
                  taxidriverstatusid: newValue,
                  updatedby: context.updatedby,
                },
              })
              .then((_rb) => {
                console.log(_rb);
                context.tempStatus = _rb[0].interim_status_name; //update backbone model
                this.prepend = context.tempStatus;
                console.log(context.tempStatus);
                console.log(this.prepend);
                $("#AddModal").modal("hide");
                $("#driverStatus").editable("setValue", this.prepend);
                value.context.$wait.end("add");
                value.context.$Notify.Success("Status Change Requested!", "");
              })
              .catch((error) => {
                value.context.$wait.end("add");

                value.context.$Notify.Error(error.data.message, "");
              })
              .finally(() => {});
          }
        }
      });
    },
  });
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
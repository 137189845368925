<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'PrepaidServices'"
      :ActiveMenu="'List'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Prepaid Voucher CashUp Details"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Prepaid"
        breadcrumbitem3="CashUp Details"
      />
      <!-- end page-header -->

      <div v-show="DATALOADED" class="btn-row">
            <div class="text-center">
              <div class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            </div>

        <!-- Modal for verification -->
      <div class="modal fade" id="modal-viewvoucher">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Voucher Details</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
              <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
                <div class="form-group">
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Ref</label>
                    <div class="col-md-9">
                       <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="VOUCHERDETAILS.Ref"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Product Name</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="VOUCHERDETAILS.ProductName"
                      />
                      
                    </div>
                  </div>
                    <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Amount</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="VOUCHERDETAILS.Amount"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">TransRef</label>
                    <div class="col-md-9">
                       <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="VOUCHERDETAILS.TransRef"
                      />
                    </div>
                  </div>
                    <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Serial</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="VOUCHERDETAILS.Serial"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Date</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="VOUCHERDETAILS.Date"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
            </div>
          </div>
        </div>
      </div>
      <!-- Modal for verification -->


      <!-- begin #Main Body Template -->

      <div class="row">
              <!-- begin col-3 -->
              <div class="col-lg-2 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  
                  <div class="stats-content">
                    <div class="stats-title">CASH BALANCE</div>
                    <div class="stats-number">{{ TOTALCASHUPBAL | newcurrencywithSymbol}}</div>
                  </div>
                </div>
              </div>
      </div>

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">CashUp Details</h4>
            </div>
            <!-- end panel-heading -->

            <!-- begin panel-body -->
            <div class="panel-body">
              <!-- begin tab-content -->
              <div class="tab-content">
                <!-- begin tab-pane -->
                <table id="data-table-combine-payments" class="table">
                <thead>
                  <tr>
                    <th nowrap>DATE</th>
                    <th nowrap>TRANSACTION TYPE</th>
                    <th nowrap>AMOUNT</th>
                    <th nowrap>TRANSACTION REFERENCE</th>
                    <th nowrap>REMAINING BALANCE</th>
                    <th nowrap>STATUS</th>
                    <th nowrap>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    
                    v-for="item in MERCHANTTRANSACTIONS"
                    :key="item._id"
                  >
                    <td>{{item._transdate + ' ' + item._transtime}}</td>
                    <td>{{item._transaction_typename}}</td>
                    <td :class="{'text-left text-red-darker': item._transaction_typename == 'Purchase','text-left text-blue-darker': item._transaction_typename == 'Deposit','text-left text-orange-darker': item._transaction_typename == 'Bank Charges',}">{{item._transaction_amount | currency}}</td>
                    <td>{{item._transaction_reference}}</td>
                    <td>{{item._remaning_balance | newcurrencywithSymbol}}</td>
                    
                    <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                      >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>
                     <button
                        v-if="item._aeon_response"
                        type="button"
                        class="btn btn-sm btn-yellow"
                        @click="() => {viewVoucher(context, item._aeon_response);}"
                      >View Voucher</button>
                      <button
                        v-if="item._transaction_typename == 'Cash Back'"
                        type="button"
                        class="btn btn-sm btn-danger"
                        @click="() => {reverseCashBack(context, item);}"
                      >
                      <v-wait v-bind:for="'reverseCashBack'+item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                      Reverse CashBack
                        </v-wait>
                      </button>
                       <!-- 
                      <button
                        v-if="item._roleusergroupid != null"
                        type="button"
                        class="btn btn-sm btn-success btnadded width-80"
                        @click="() => {removeSystemuserUserGroup(context,DELETESYSTEMGROUPROLE,item._roleusergroupid); roles._roleusergroupid = null }"
                      >Added</button>-->
                    </td>
                  </tr>
                </tbody>
              </table>
                <!-- end tab-pane -->
              </div>
              <!-- end tab-content -->
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("prepaidservices");

export default {
  name: "ManageVehicles",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      VOUCHERDETAILS:{
        Ref: "",
        ProductName:"",
        TransRef:"",
        Serial:"",
        Amount:"",
      },
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      collectorid: this.$route.params.collectorid,
      startdate: this.$route.params.startdate,
      enddate: this.$route.params.enddate,
      activesession: this.$route.params.activesession,
      skip: 0,
      length: 10000,
      startDate: {},
      GETCOLLECTORIDPAYMENTHISTORY:[],
      endDate: {},
      paymentsloaded: false,
      arearsvehiclesloaded: false,
      arrearsoperatorsloaded: false,
      MERCHANTTRANSACTIONS: [],
      DATALOADED: false,
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters({
    }),
    TOTALCASHUPBAL: function(){
      let sum = 0;
      this.MERCHANTTRANSACTIONS.forEach(function(item) {
        if(item._transaction_amount && item._transaction_typename === 'Purchase')
         sum += (parseFloat(item._transaction_amount));
      });

     return sum;
   },
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
     ...mapActions([
      "getMerchantTransactions",
    ]),
    viewVoucher: (context, response) => {
      
      context.VOUCHERDETAILS = response.data;
      $("#modal-viewvoucher").modal("show");
    },
  },
  beforeMount: function() {},
  mounted: function() {
    //this.getbyid(this.context, this.taxiassociationid);

    App.init();

    

    const context = this;

    let _enddate = context.$Toolkit.ConvertToServerDate(new Date());

    

    if(context.activesession === 'false')
    {
      _enddate = context.enddate;
      inprogress = false;
    }
   
var payload = {
      taxiassociationsId: context.taxiassociationid,
      agentid: context.collectorid,
      startDate: context.$Toolkit.ConvertToServerDate(context.startdate),
      endDate: context.$Toolkit.ConvertToServerDate(_enddate),
    };

    context.DATALOADED = true;


      context
        .getMerchantTransactions({
          router: context.$router,
          payload
        })
        .then((_rb) => {
          context.DATALOADED = false;
          context.MERCHANTTRANSACTIONS = _rb;
        })
        .catch((error) => {
          console.log("transaction types errors: ", error);
        }).finally(() => {
         var options = {
                dom: "lBfrtip",
                buttons: [
                  { extend: "copy", className: "btn-sm" },
                  { extend: "csv", className: "btn-sm" },
                  { extend: "excel", className: "btn-sm" },
                  { extend: "pdf", className: "btn-sm" },
                  { extend: "print", className: "btn-sm" }
                ],
                responsive: false,
                autoFill: false,
                colReorder: false,
                keys: false,
                rowReorder: false,
                select: false
              };

              // if ($(window).width() <= 767) {
              //   options.rowReorder = false;
              //   options.colReorder = false;
              //   options.autoFill = false;
              // }

              $("#data-table-combine-payments").DataTable(options);
      
      });

  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>


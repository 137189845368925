<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
      v-on:init="init"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'TaxiVIP'"
      :ActiveMenu="'VIPDashboard'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <transition name="bounce">
      <div id="content" class="content" v-show="USERPROFILE.taxi_associations_id">
        <!-- begin page-header -->
        <headerpagebreadcrumb
          pageheaderTitle="VIP Card - Dashboard"
          pageheaderdescription
          breadcrumbitem1="Home"
          breadcrumbitem2="VIP Card"
          breadcrumbitem3="Dashboard"
        />
        <!-- end page-header -->
        <!-- begin #Main Body Template -->

        <!-- begin row -->
        <div class="row">
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fa fa-solid fa-credit-card"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Revenue</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalrebate | currencywithSymbol}}</div>

                <div class="stats-desc">Total Revenue</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6" :v-if="ISINTERNALSYSTEMUSER">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fa fa-solid fa-credit-card"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">This Month's Revenue</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.thismonthsrebate | currencywithSymbol}}</div>

                <div class="stats-desc">This Month's Revenue</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fa fa-solid fa-credit-card"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">This Week's Revenue</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.thisweeksrebate | currencywithSymbol}}</div>

                <div class="stats-desc">This Week's Revenue</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-green">
                <i class="fa fa-solid fa-credit-card"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Today's Revenue</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.todaysrebate | currencywithSymbol}}</div>

                <div class="stats-desc">Today's Revenue</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->

           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fa fa-solid fa-credit-card"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Total Deposits</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.totalfuelamount | currencywithSymbol}}</div>

                <div class="stats-desc">Total Deposits {{GETDASHBOARDCARDS.totalfuel}} </div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fa fa-solid fa-money-bill"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">This Month's Deposits</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.thismonthsfuelamount | currencywithSymbol}}</div>

                <div class="stats-desc">This Month's Deposits {{GETDASHBOARDCARDS.thismonthsfuel}} </div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fa fa-solid fa-money-bill"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">This Week's Deposits</div>
                <div class="stats-number"> {{GETDASHBOARDCARDS.thisweeksfuelamount | currencywithSymbol}}</div>

                <div class="stats-desc">This Week's Deposits {{GETDASHBOARDCARDS.thisweeksfuel}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
           <!-- begin col-3 -->
           <div class="col-lg-3 col-md-6">
            <div class="widget widget-stats bg-white text-inverse">
              <div class="stats-icon stats-icon-square bg-gradient-orange">
                <i class="fa fa-solid fa-money-bill"></i>
              </div>
              <div class="stats-content">
                <div class="stats-title">Today's Deposits</div>
                <div class="stats-number">{{GETDASHBOARDCARDS.todaysfuelamount | currencywithSymbol}}</div>

                <div class="stats-desc">Today's Deposits {{GETDASHBOARDCARDS.todaysfuel}}</div>
              </div>
            </div>
          </div>
          <!-- end col-3 -->
  
        </div>

        <!-- end row -->

         <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

             <!-- begin panel -->
             <div :v-if="ISINTERNALSYSTEMUSER" class="panel panel-inverse hide" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">CASH DEPOSITS - INSTERNAL USE</h4>
              </div>
              <div class="panel-body">
                <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th></th>
                  <th>TAXI ASSOCIATION</th>
                  <th>AMOUNT</th>
                  <th>{{TAXIABBR}}</th>
                  <th>TAMS</th>
                  <th>DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr
                 v-for="(item,index) in GETALLTAXIASSDEPOSITS"
                  v-bind:key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                <td>{{++index}}</td>
                  <td>{{item.name}}</td>
                  <td>{{item.amount | newcurrencywithSymbol}}</td>
                  <td>{{item.amounttaxi | newcurrencywithSymbol}}</td>
                  <td>{{item.amounttams | newcurrencywithSymbol}}</td>
                  <td>{{item.tmonth}}</td>
                </tr>
              </tbody>
            </table>
          </div>
              </div>
            </div>
            <!-- end panel -->
            
              <!-- begin panel -->
              <div :v-if="ISINTERNALSYSTEMUSER" class="panel panel-inverse hide" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">REVENUE - INTERNAL USE</h4>
              </div>
              <div class="panel-body">
                <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th></th>
                  <th>TAXI ASSOCIATION</th>
                  <th>AMOUNT</th>
                  <th>{{TAXIABBR}}</th>
                  <th>TAMS</th>
                  <th>DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr
                 v-for="(item,index) in GETALLTAXIASSREVENUE"
                  v-bind:key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                <td>{{++index}}</td>
                  <td>{{item.name}}</td>
                  <td>{{item.amount | newcurrencywithSymbol}}</td>
                  <td>{{item.amounttaxi | newcurrencywithSymbol}}</td>
                  <td>{{item.amounttams | newcurrencywithSymbol}}</td>
                  <td>{{item.tmonth}}</td>
                </tr>
              </tbody>
            </table>
          </div>
              </div>
            </div>
            <!-- end panel -->

                <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-1">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Weekly Revenue</h4>
              </div>
              <div class="panel-body">
                <div id="interactive-chartOpbyAss" class="height-sm"></div>
              </div>
            </div>
            <!-- end panel -->
          </div>
         
        </div>
        <!-- end row -->


        <!-- begin row -->
        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-8">

           <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-2">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Total Revenue By Route</h4>
              </div>
              <div class="panel-body">
                <div id="donut-OperatorByGender" class="height-lg"></div>
              </div>
            </div>
            <!-- end panel -->



          </div>

         
          <!-- end col-8 -->
          <!-- begin col-8 -->
          <div class="col-xl-4">

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-6">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 VIP Cards</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
                        <th>Card No</th>
                        <th>Customer</th>
                        <th>Total Fares</th>
                        <th>Total Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATS.top5vehicles"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                          {{item.cardnumber}}
                        </td>
                        <td>{{item.fullname}}</td>
                        <td>{{item.totalfares | newcurrencywithSymbol}}</td>
                        <td>{{item.total_vipfees | newcurrencywithSymbol}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

              <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-10">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Customers</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
						            <th>Full Name</th>
                        <th>No Of Cards</th>
                        <th>Deposits</th>
                        <th>Revenue</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATS.top5operators"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                          {{item.fullname}}
                        </td>
                        <td>{{item.num_of_cards}}</td>
                        <td>{{item.totaldeposits | newcurrencywithSymbol}}</td>
                        <td>{{item.total_vipfees | newcurrencywithSymbol}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->

              <!-- begin panel -->
              <div class="panel panel-inverse" data-sortable-id="index-10">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">Top 5 Marshals</h4>
              </div>
              <div class="panel-body p-t-0">
                <div class="table-responsive">
                  <table class="table table-valign-middle">
                    <thead>
                      <tr>
						            <th>Full Name</th>
                        <th>Total Cards</th>
                        <th>Deposits</th>
                        <th>Fees</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
					  v-for="(item,index) in GETOPERATORFINSTATS.top5marshals"
                    v-bind:key="item.id"
                    :class="index & 1 ? 'odd gradeA' : 'even'"
					  >
                        <td>
                          {{item.fullname}}
                        </td>
                        <td>{{item.num_of_cards}}</td>
                        <td>{{item.totaldeposits | newcurrencywithSymbol}}</td>
                        <td>{{item.total_vipfees | newcurrencywithSymbol}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end panel -->
 

          </div>
          <!-- end col-8 -->
         
        </div>
        <!-- end row -->

        <div class="row">
          <!-- begin col-8 -->
          <div class="col-xl-12">

            <!-- begin panel -->
            <div class="panel panel-inverse" data-sortable-id="index-3">
              <div class="panel-heading">
                <div class="panel-heading-btn">
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-default"
                    data-click="panel-expand"
                  >
                    <i class="fa fa-expand"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-success"
                    data-click="panel-reload"
                  >
                    <i class="fa fa-redo"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-warning"
                    data-click="panel-collapse"
                  >
                    <i class="fa fa-minus"></i>
                  </a>
                  <a
                    href="javascript:;"
                    class="btn btn-xs btn-icon btn-circle btn-danger"
                    data-click="panel-remove"
                  >
                    <i class="fa fa-times"></i>
                  </a>
                </div>
                <h4 class="panel-title">VIP Transactions</h4>
              </div>
              <div class="panel-body">
                <div class="table-responsive">
            <table class="table table-invoice">
              <thead>
                <tr>
                  <th></th>
                  <th>CUSTOMER</th>
                  <th>CARD NO</th>
                  <th>DESCRIPTION</th>
                  <th>AMOUNT</th>
                  <th>BALANCE</th>
                  <th>DATE</th>
                  <th>ADDED BY</th>
                  <th class="text-nowrap" :v-if="ISINTERNALSYSTEMUSER">ACTION</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item,index) in GETALLTRANSACTIONS"
                  v-bind:key="item.id"
                  :class="index & 1 ? 'odd gradeA' : 'even'"
                >
                <td>{{++index}}</td>
                  <td>{{item.operator_name}} {{item.operator_surname}}</td>
                  <td>{{item.cardno}}</td>
                  <td>{{item.description}}</td>
                  <td>{{item.transaction_amount | newcurrencywithSymbol}}</td>
                  <td>{{item.remaning_balance | newcurrencywithSymbol}}</td>
                  <td>{{item.date_added}}</td>
                  <td>{{item.addedbyname}} {{item.addedbysurname}}</td>
                  <td :v-if="ISINTERNALSYSTEMUSER" class="with-btn valign-middle" nowrap >
                      <div class="btn-group" role="group" aria-label="Basic example">
                        <router-link
                        :to="{ name: 'commuterprofile', params: { id: item.commuters_id }}"
                        class="btn btn-sm btn-success width-80 m-r-2"
                      >Profile</router-link>
                      <button v-if="item.is_active && ISINTERNALSYSTEMUSER" type="button" @click="Del(context,item)" class="btn btn-sm btn-danger"
                        :disabled="$wait.is('UpdateNextOfKinAction-' +item.id)">
                 <v-wait v-bind:for="'UpdateNextOfKinAction-' + item.id">
                    <template slot="waiting">
                      <i class="fas fa-spinner fa-spin"></i>
                    </template>
                   Delete
                  </v-wait>
                        
                    </button> 
                  </div>
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
              </div>
            </div>
            <!-- end panel -->



          </div>

        
         
        </div>
        <!-- end row -->

      
        <!-- begin row -->

        <!-- begin #Main Body Template -->
      </div>
    </transition>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import statsCards from "@/components/charts/statsCards.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers(
  "taxivip"
);

export default {
  name: "taxivip_dashboard",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      TAXIABBR: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].abbr,
      GETDASHBOARDCARDS: {},
      GETOPERATORFINSTATS: {},
      GETOPERATORFINSTATSTOP5: {},
      GETALLTRANSACTIONS: [],
      GETALLTAXIASSREVENUE: [],
      GETALLTAXIASSDEPOSITS: [],
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      USERID: this.$store.getters["auth/USERPROFILE"].system_usersid,
      context: this,
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    statsCards,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters(["", ""])
  },
  methods: {
    ...mapActions(["getdashboardcards","getalltransactions", "getoperatorfinstats"]),
    init: function() {},
    Del: (context,item) => {
      context.$Notify
          .YesNo(false, { context, item })
          .then(value => {
            if (value) {
              context.$wait.start("UpdateNextOfKinAction-" + value.item.id);
              const router = value.context.$router;
            var payload = {
              id: value.item.id,
              deletedby: value.context.USERID,
              router
            };
            value.context.$store
              .dispatch("fuelmanager_dashboard/DeleteFuelTransactions", payload)
              .then(parsedResult => {
                //const context = $(document).data("context");

               

                if(parsedResult.status){
                  value.context.GETALLTRANSACTIONS = value.context.GETALLTRANSACTIONS.filter(item => item.id !== value.item.id)
                value.context.$Notify.Success(parsedResult.message, "");
                value.item.is_active = false;
                context.$wait.end("UpdateNextOfKinAction-" + value.item.id);
                }
              })
              .catch(error => {
                context.$wait.end("UpdateNextOfKinAction-" + value.item.id);
              });
                  }
                });

     
    },
  },
  mounted: function() {
    $(document).data("context", this);
    App.init();

    const router = this.$router;

    var payload = {
      taxiassociationsId: this.taxiassociationid
    };

    this.getdashboardcards({ router, payload })
      .then(parsedResultOP => {
        //const context = $(document).data("context");

        

        this.GETDASHBOARDCARDS = parsedResultOP;

      
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });

      this.getalltransactions({ router, payload })
      .then(parsedResultOP => {
        //const context = $(document).data("context");

        debugger;

        this.GETALLTRANSACTIONS = parsedResultOP.data;
        this.GETALLTAXIASSREVENUE = parsedResultOP.tdata;
        this.GETALLTAXIASSDEPOSITS = parsedResultOP.ddata;
        

      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      }).finally((error)=>{
          var options = {
                    responsive: false,
                    autoFill: false,
                    colReorder: false,
                    keys: false,
                    rowReorder: false,
                    select: false,
                    order: [[0, 'asc']]
                  };

                  // if ($(window).width() <= 767) {
                  //   options.rowReorder = false;
                  //   options.colReorder = false;
                  //   options.autoFill = false;
                  // }

 	              $(".table-invoice").DataTable(options);
        })


    var handleOperatorByGender = function(json_data) {
      "use strict";
      if ($("#donut-OperatorByGender").length !== 0) {
        const context = $(document).data("context");

        const colors = [
        COLOR_RED,
        COLOR_ORANGE,
        COLOR_GREEN,
        COLOR_AQUA,
        COLOR_BLUE,
        COLOR_PURPLE,
        COLOR_GREY,
        COLOR_BLACK,
        COLOR_BLACK
      ];

      debugger


        var operatorGenderData = [];
        

        for(var i in json_data.fuelbytype){
          operatorGenderData.push({
            label: json_data.fuelbytype[i].fueltypes_desc + " - R" + json_data.fuelbytype[i].rebate_fee + "",
            data: parseFloat(json_data.fuelbytype[i].rebate_fee),
            color: colors[i]
          })
      }

        $.plot("#donut-OperatorByGender", operatorGenderData, {
          series: {
            pie: {
              innerRadius: 0.5,
              show: true,
              label: {
                show: true
              }
            }
          },
          grid: {
            hoverable: true,
            clickable: true
          },
          legend: {
            show: true
          }
        });
      }
	};
	


var handleTotalOpPerTaxiAssBarChart = function (json_data) {
'use strict';
	
	if ($('#interactive-chartOpbyAss').length !== 0) {

    const context = $(document).data("context");

    //var json_data = context.GETOPERATORFINSTATS;

  var data_dep = [];
  var data_pay = [];
  var data_30days = [];

  
   

  for(var i in json_data.days){
    data_dep.push(json_data.v_row_rebate[i].rebate);
    data_pay.push(json_data.v_row_totalprice[i].rebatelast);
    data_30days.push(json_data.days[i].days);
  }
    
          
	
	var options = {
		chart: {
			height: 350,
			type: 'bar'
		},
		title: {
			text: 'Weekly Revenue Last Year vs This Year',
			align: 'center'
		},
		plotOptions: {
			bar: {
				horizontal: false,
				columnWidth: '100%',
        endingShape: 'rounded',
        dataLabels: {
          position: 'top'
        }
			},
		},
		dataLabels: {
      enabled: true
		},
		stroke: {
			show: true,
			width: 2,
			colors: ['transparent']
		},
		colors: [COLOR_GREEN,COLOR_RED],
		series: [{
			name: 'This Year',
			data: data_dep
		}, {
			name: 'Last Year',
			data: data_pay
		}],
		xaxis: {
			categories: data_30days,
			axisBorder: {
				show: true,
				color: COLOR_SILVER_TRANSPARENT_5,
				height: 1,
				width: '100%',
				offsetX: 0,
				offsetY: -1
			},
			axisTicks: {
				show: true,
				borderType: 'solid',
				color: COLOR_SILVER,
				height: 6,
				offsetX: 0,
				offsetY: 0
			}
		},
		yaxis: {
			title: {
				text: 'Rands'
			}
		},
		fill: {
			opacity: 1
		},
		tooltip: {
			y: {
				formatter: function (val) {
					return + val
				}
			}
		}
	};
	
	var chart = new ApexCharts(
		document.querySelector('#interactive-chartOpbyAss'),
		options
	);

	chart.render();
	
	}
};

  

this.getoperatorfinstats({ router, payload })
      .then(parsedResultOP => {
        const context = $(document).data("context");

        debugger;

        context.GETOPERATORFINSTATS = parsedResultOP;

    // handleOperatorByAgeGroup();
		// handleVehicleWithNoOperatingLicense();
    handleTotalOpPerTaxiAssBarChart(parsedResultOP);
    handleOperatorByGender(parsedResultOP);

    // handleVehicleWithfinancechartdisk();

    
      })
      .catch(error => {
        //context.$wait.end("generatestatement");
      });
      
  }
};
</script>

<style>
@import "/assets/plugins/nvd3/build/nv.d3.css";
</style>
<template>
  <div class="home">
    <!-- begin #page-container -->

    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- end #header -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'PrepaidServices'"
      :ActiveMenu="'List'"
    />
    <!-- end #sidebar -->
    <!-- end #sidebar -->
    <!-- begin #content -->
    <div id="content" class="content content-full-width">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="Merchant Account"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Merchants"
        breadcrumbitem3="Profile"
      />
      <!-- end page-header -->


      <!-- Modal for verification -->
      <div class="modal fade" id="modal-viewvoucher">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Voucher Details</h4>
              <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
              <form action="/" method="POST" name="form-wizard" class="form-control-with-bg">
                <div class="form-group">
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Ref</label>
                    <div class="col-md-9">
                       <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="VOUCHERDETAILS.Ref"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Product Name</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="VOUCHERDETAILS.ProductName"
                      />
                      
                    </div>
                  </div>
                    <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Amount</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="VOUCHERDETAILS.Amount"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">TransRef</label>
                    <div class="col-md-9">
                       <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="VOUCHERDETAILS.TransRef"
                      />
                    </div>
                  </div>
                    <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Serial</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="VOUCHERDETAILS.Serial"
                      />
                    </div>
                  </div>
                  <div class="form-group row m-b-10">
                    <label class="col-md-3 text-md-right col-form-label">Date</label>
                    <div class="col-md-9">
                      <input
                        type="text"
                        data-parsley-group="step-1"
                        data-parsley-required="true"
                        class="form-control"
                        disabled
                        v-model="VOUCHERDETAILS.Date"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <input type="button" class="btn btn-default" data-dismiss="modal" value="Cancel" />
            </div>
          </div>
        </div>
      </div>
      <!-- Modal for verification -->

      <hr class="bg-grey-lighter" />
      <!-- begin profile -->
      <div class="profile">
        <div class="profile-header">
          <!-- BEGIN profile-header-cover -->
          <div class="profile-header-cover"></div>
          <!-- END profile-header-cover -->
          <!-- BEGIN profile-header-content -->
          <div class="profile-header-content">
            <!-- BEGIN profile-header-img -->
            <div class="profile-header-img">
              <img v-if="PICTURE" :src="`${PICTURE != undefined ? PICTURE : '' } `" alt />
            </div>
            <!-- END profile-header-img -->
            <!-- BEGIN profile-header-info -->
            <div class="profile-header-info">
              <h4 class="m-t-10 m-b-5"></h4>
              <p class="m-b-10"></p>
              <a href="#" class="btn btn-xs btn-yellow">Edit Profile</a>
            </div>
            <!-- END profile-header-info -->
          </div>
          <!-- END profile-header-content -->
          <!-- BEGIN profile-header-tab -->
          <ul class="profile-header-tab nav nav-tabs width-full">
            <li class="nav-item">
              <a href="#profile-about" class="nav-link active" data-toggle="tab">ABOUT</a>
            </li>
            <li class="nav-item">
              <a href="#profile-photos" class="nav-link" data-toggle="tab">TRANSACTION HISTORY</a>
            </li>
            <li class="nav-item">
              <a href="#profile-roles" class="nav-link" data-toggle="tab">TRANSACT</a>
            </li>
            <li class="nav-item">
              <a href="#profile-cashtranfer" class="nav-link" data-toggle="tab">CASH TRANSFER</a>
            </li>
          </ul>
          <!-- END profile-header-tab -->
        </div>
      </div>
      <!-- end profile -->
      <!-- begin profile-content -->
      <div class="profile-content">
        <!-- begin tab-content -->
        <div class="tab-content p-0">
          <!-- begin #profile-about tab -->
          <div class="tab-pane fade show active" id="profile-about">
            <!-- begin table -->
            <div class="table-responsive">
              <table class="table table-profile">
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <h4>
                        {{ taxiassociation_name || 'No Association'}}
                        <small>{{taxiass_abbr_name || 'No Association code'}}</small>
                      </h4>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="field">
                      <i class="fa fa-tag fa-lg m-r-5"></i> Title
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <select
                            class="form-control"
                            data-size="10"
                            id="title"
                            data-live-search="true"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            disabled
                            v-model="title_id"
                          >
                            <option
                              v-bind:key="item._id"
                              v-for="item in GETALLTITLES"
                              :value="item._id"
                            >{{item._name}}</option>
                          </select>
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-id-card fa-lg m-r-5"></i>Username
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <input
                            type="text"
                            id="username"
                            name="username"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            class="form-control"
                            disabled
                            v-model="username"
                          />
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-user fa-lg m-r-5"></i>Firstname
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <input
                            type="text"
                            id="firstname"
                            name="firstname"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            class="form-control"
                            disabled
                            v-model="firstname"
                          />
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-user fa-lg m-r-5"></i>Surname
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <input
                            type="text"
                            id="surname"
                            name="surname"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            class="form-control"
                            disabled
                            v-model="surname"
                          />
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-barcode fa-lg m-r-5"></i> ID Number
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <input
                            type="text"
                            id="idNumber"
                            name="idNumber"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            class="form-control"
                            placeholder="N/A"
                            disabled
                            v-model="id_number"
                          />
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-mobile fa-lg m-r-5"></i>Mobile
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <input
                            type="number"
                            id="mobileNumber"
                            name="surname"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            class="form-control"
                            disabled
                            v-model="mobile_number"
                          />
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <tr class="divider">
                    <td colspan="2"></td>
                  </tr>
                  <tr>
                    <td class="field">
                      <i class="fa fa-venus-mars fa-lg m-r-5"></i>Gender
                    </td>
                    <td>
                      <!-- begin form-group -->
                      <div class="form-group row m-b-10">
                        <div class="col-md-6">
                          <select
                            class="form-control"
                            id="gender"
                            data-size="10"
                            data-live-search="true"
                            data-parsley-group="step-1"
                            data-parsley-required="false"
                            disabled
                            v-model="gender_id"
                          >
                            <option value="0" selected>Select a Gender</option>
                            <option
                              v-bind:key="item._id"
                              v-for="item in GETALLGENDERS"
                              :value="item._id"
                            >{{item._name}}</option>
                          </select>
                        </div>
                      </div>
                      <!-- end form-group -->
                    </td>
                  </tr>
                  <!-- <tr class="highlight" v-if="!passwordChanger">
                    <td class="field">&nbsp;</td>
                    <td class="p-t-10 p-b-10">
                      <div
                        
                        class="alert alert-white fade show"
                      >
                        <button
                          v-if="!enabled"
                          @click="enableFields(context)"
                          type="button"
                          class="btn btn-yellow width-150 m-5"
                        >Update Profile</button>
                        <button
                          v-if="enabled"
                          @click="systemuserUpdate(context,UPDATEUSERPROFILE)"
                          type="button"
                          class="btn btn-success width-150 m-5"
                        >Update</button>
                        <button
                          type="submit"
                          class="btn btn-primary width-150 m-r-2"
                          href="#modal-dialog"
                          data-toggle="modal"
                        >Change Password</button>
                      </div>
                    </td>
                  </tr>-->
                </tbody>
              </table>
            </div>
            <!-- end table -->
          </div>
          <!-- end #profile-about tab -->
          <!-- begin #profile-photos tab -->
          <div class="tab-pane fade in" id="profile-photos">
            <!-- end page-header -->
            <div class="row">


              <!-- begin col-3 -->
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-ios-card"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">ACCOUNT NUMBER</div>
                    <div class="stats-number">{{ accountNumber || 'N/A'}}</div>
                  </div>
                </div>
              </div>
              <!-- end col-3 -->
              <!-- begin col-3 -->
              <!-- <div class="col-lg-3 col-md-6">
          <div class="widget widget-stats bg-white text-inverse">
            <div class="stats-icon stats-icon-square bg-gradient-blue">
              <i class="ion-ios-hourglass"></i>
            </div>
            <div class="stats-content">
              <div class="stats-title">ACCOUNT STATUS</div>
              <div class="stats-number">{{ 'Pending'}}</div>
            </div>
          </div>
              </div>-->
              <!-- end col-3 -->
              <!-- begin col-3 -->
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-logo-usd"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">AVAILABLE BALANCE</div>
                    <div class="stats-number">{{ availableBalance || 0 | newcurrencywithSymbol}}</div>
                  </div>
                </div>
              </div>
              <!-- end col-3 -->
                <!-- begin col-3 -->
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-logo-usd"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">CASHUP LIMIT</div>
                    <div class="stats-number">{{ CASHUPLIMIT || 0 | newcurrencywithSymbol}}</div>
                  </div>
                </div>
              </div>
              <!-- end col-3 -->
                 <!-- begin col-3 -->
              <div class="col-lg-3 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  <div class="stats-icon stats-icon-square bg-gradient-blue">
                    <i class="ion-logo-usd"></i>
                  </div>
                  <div class="stats-content">
                    <div class="stats-title">TOTAL PURCHASES</div>
                    <div class="stats-number">{{ TOTALCASHUPBAL || 0 | newcurrencywithSymbol}}</div>
                  </div>
                </div>
              </div>
              <!-- end col-3 -->
            </div>
            <!-- begin table-responsive -->

            	<div id="advance-daterange" class="btn btn-default btn-block text-left m-b-10 width-400 pull-left m-r-40">
                        <i class="fa fa-caret-down pull-right m-t-2"></i>
                        <span></span>
                         <div v-show="$wait.is('loading')" class="spinner-border spinner-border-lg text-info"  role="status">
                <span class="sr-only">Loading...</span>
              </div>
                      </div>

                      <a href="javascript:;" data-click="swal-warning" @click="ShowControlPanel(context)" class="btn btn-warning pull-left m-l-40">Show ToolBar</a>
                      
                      
            <div class="table-responsive">

              
              <table class="table" id="listoftransactions">
                <thead>
                  <tr>
                    <th nowrap>DATE</th>
                    <th nowrap>TRANSACTION TYPE</th>
                    <th nowrap>AMOUNT</th>
                    <th nowrap>TRANSACTION REFERENCE</th>
                    <th nowrap>REMAINING BALANCE</th>
                    <th nowrap>STATUS</th>
                    <th nowrap>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    
                    v-for="item in MERCHANTTRANSACTIONS"
                    :key="item._id"
                  >
                    <td>{{item._transdate + ' ' + item._transtime}}</td>
                    <td>{{item._transaction_typename}}</td>
                    <td :class="{'text-left text-red-darker': item._transaction_typename == 'Purchase','text-left text-blue-darker': item._transaction_typename == 'Deposit','text-left text-orange-darker': item._transaction_typename == 'Bank Charges',}">{{item._transaction_amount | currency}}</td>
                    <td>{{item._transaction_reference}}</td>
                    <td>{{item._remaning_balance | newcurrencywithSymbol}}</td>
                    
                    <td>
                      <span
                        class="badge f-s-12"
                        v-bind:class="{ 'badge-success': item._is_active, 'label-secondary':!item._is_active  }"
                      >{{item._is_active == true ? 'Active' : 'Disabled' }}</span>
                    </td>
                    <td>
                     <button
                        v-if="item._aeon_response"
                        type="button"
                        class="btn btn-sm btn-yellow"
                        @click="() => {viewVoucher(context, item._aeon_response);}"
                      >View Voucher</button>
                      <button
                        v-if="item._transaction_typename == 'Cash Back'"
                        type="button"
                        class="btn btn-sm btn-danger"
                        @click="() => {reverseCashBack(context, item);}"
                      >
                      <v-wait v-bind:for="'reverseCashBack'+item._id">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                      Reverse CashBack
                        </v-wait>
                      </button>
                       <!-- 
                      <button
                        v-if="item._roleusergroupid != null"
                        type="button"
                        class="btn btn-sm btn-success btnadded width-80"
                        @click="() => {removeSystemuserUserGroup(context,DELETESYSTEMGROUPROLE,item._roleusergroupid); roles._roleusergroupid = null }"
                      >Added</button>-->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->
          </div>
          <!-- end #profile-photos tab -->
          <!-- begin #profile-roles tab -->
          <div class="tab-pane fade in" id="profile-roles">
            <!-- begin panel-body -->
            <div class="panel-body">
              <!-- begin fieldset -->
              <fieldset>
                <!-- begin row -->
                <div class="row">
                  <div class="col-md-8 offset-md-2">
                    <legend
                      class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"
                    >Top Up Or Add Charge</legend>
                  </div>

                  <!-- begin col-8 -->
                  <div class="col-md-8 offset-md-2">
                    <legend class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"></legend>

                    <div class="form-group row m-b-10">
                      <label class="col-md-3 col-form-label text-md-right">
                        Amount
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-6">
                        <CurrencyInput
                          :auto-decimal-mode="true"
                          :currency="currency"
                          data-parsley-group="step-1"
                          data-parsley-required="true"
                          class="form-control text-left demo__currency-input"
                          v-model="ADDTRANSACTION.amount"
                        />
                      </div>
                    </div>
                    <!-- end form-group -->

                    <!-- begin form-group -->

                    <div class="form-group row m-b-10">
                      <label class="col-md-3 col-form-label text-md-right">
                        Transaction Type
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-6">
                        <select
                          class="form-control"
                          data-size="10"
                          data-live-search="true"
                          data-parsley-group="step-1"
                          data-parsley-required="true"
                          data-parsley-min="1"
                          v-model="ADDTRANSACTION.transaction_type_id"
                        >
                          <option value="0" selected>Select Transaction Type</option>
                          <option
                            v-bind:key="item._id"
                            v-for="item in TRANSACTIONTYPE"
                            :value="item.id"
                          >{{item.name}}</option>
                        </select>
                      </div>
                    </div>
                    <!-- end form-group -->

                    <!-- begin form-group -->
                    <div class="form-group row m-b-10">
                      <label class="col-md-3 col-form-label text-md-right">
                        Reference:
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-6">
                        <textarea
                          placeholder="Type your reference"
                          class="form-control m-b-10"
                          id="clipboard-textarea"
                          rows="5"
                        ></textarea>
                      </div>
                    </div>
                    <!-- end form-group -->
                    <div class="btn-row">
                      <div class="text-center">
                        <button
                          @click="transact(context,ADDTRANSACTION)"
                          class="btn btn-primary btn-lg width-120"
                        :disabled="$wait.is('trasact')"
                      >
                        <v-wait v-bind:for="'trasact'">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Submit
                        </v-wait>
                      </button>
                      </div>
                    </div>
                  </div>
                  <!-- end col-8 -->
                </div>
                <!-- end row -->
              </fieldset>
              <!-- end fieldset -->
            </div>
            <!-- End panel-body -->
          </div>
          <!-- end #profile-roles tab -->

          <!-- begin #profile-cashtranfer tab -->
          <div class="tab-pane fade in" id="profile-cashtranfer">
            <!-- begin panel-body -->
            <div class="panel-body">
              <!-- begin fieldset -->
              <fieldset>
                <!-- begin row -->
                <div class="row">
                  <div class="col-md-8 offset-md-2">
                    <legend
                      class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"
                    >Cash Transfer Between Marshals</legend>
                  </div>

                  <!-- begin col-8 -->
                  <div class="col-md-8 offset-md-2">
                    <legend class="no-border f-w-700 p-b-0 m-t-0 m-b-20 f-s-16 text-inverse"></legend>

                    <div class="form-group row m-b-10">
                      <label class="col-md-3 col-form-label text-md-right">
                        Amount
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-6">
                        <CurrencyInput
                          :auto-decimal-mode="true"
                          :currency="currency"
                          data-parsley-group="step-1"
                          data-parsley-required="true"
                          class="form-control text-left demo__currency-input"
                          v-model="CASNTRANSFERTRANSACTION.amount"
                        />
                      </div>
                    </div>
                    <!-- end form-group -->

                      <div class="form-group row m-b-10">
                      <label class="col-md-3 col-form-label text-md-right">
                        From
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-6">
                        <input disabled :value="firstname + ' ' + surname"  inputmode="text" data-parsley-required="true" class="form-control text-left">
                       
                      </div>
                    </div>

                    <!-- begin form-group -->
                    <div class="form-group row m-b-10">
                      <label class="col-md-3 col-form-label text-md-right">
                        To
                        <span class="text-danger">*</span>
                      </label>
                      <div class="col-md-6">
                         <select
                              class="form-control lisofmarshals default-select"
                              data-size="5"
                              data-live-search="true"
                              data-parsley-group="step-1"
                              data-parsley-required="true"
                              data-parsley-min="1"
                              
                            >
                              <option value="0" selected>Select System User</option>
                              <option
                              v-for="item in GETSYSTEMUSERS"
                              :key="item._id"
                              :value="item._id"
                            >{{item._firstname}} {{item._surname}}</option>
                            </select>
                      </div>
                    </div>
                    <!-- end form-group -->
 
                    <div class="btn-row">
                      <div class="text-center">
                        <button
                          @click="transactCashTransfer(context,CASNTRANSFERTRANSACTION)"
                          class="btn btn-primary btn-lg width-120"
                        :disabled="$wait.is('transactCashTransfer')"
                      >
                        <v-wait v-bind:for="'transactCashTransfer'">
                          <template slot="waiting">
                            <i class="fas fa-spinner fa-spin"></i>
                          </template>
                          Submit
                        </v-wait>
                      </button>
                      </div>
                    </div>
                  </div>
                  <!-- end col-8 -->
                </div>
                <!-- end row -->
              </fieldset>
              <!-- end fieldset -->
            </div>
            <!-- End panel-body -->
          </div>
          <!-- end #profile-roles tab -->

        </div>
        <!-- end tab-content -->
      </div>
      <!-- end profile-content -->
    </div>
    <!-- end #content -->

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import AudioVideoControl from "@/components/common/AudioVideoControl.vue";
import DropZoneControl from "@/components/common/DropZoneControl.vue";
import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";
import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("prepaidservices");

export default {
  name: "DriverProfile",
  data: function () {
    return {
      currencySymbol: "R ",
      position: "prefix",
      VOUCHERDETAILS:{
        Ref: "",
        ProductName:"",
        TransRef:"",
        Serial:"",
        Amount:"",
      },
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      isSystemAdmin: this.$store.getters["auth/USERPROFILE"].is_system_admin,
      acceptedFiles: "application/pdf",
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      SYSTEMUSERPROFILE: [],
      GETALLTITLES: [],
      GETALLGENDERS: [],
      context: this,
      id: this.$route.params.id,
      skip: 0,
      length: 10000000,
      TOTALCOMMISSION: 0,
      CASHUPLIMIT: 0,
      DEFAULTPICTURE: "/assets/img/user/user-13.jpg",
      PICTURE: undefined,
      updatedby: this.$store.getters["auth/USERPROFILE"].system_usersid,
      accountNumber: "",
      accountStatus: "",
      availableBalance: null,
      title_id: 0,
      title: "",
      gender_id: 0,
      gender: "",
      username: "",
      firstname: "",
      surname: "",
      id_number: "",
      mobile_number: "",
      taxiassociation_name: "",
      taxiass_abbr_name: "",
      TRANSACTIONTYPE: [],
      MERCHANTTRANSACTIONS: [],
      dataLoadedgrid: false,
      dataLoaded: false,
      GETSYSTEMUSERS: [],
      CASNTRANSFERTRANSACTION: {
        taxiassociations_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        from_systemuser_id: this.$route.params.id,
        to_systemuser_id: 0,
        amount: 0,
        capturedby_id: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
      ADDTRANSACTION: {
        taxiassociations_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"]
          .id,
        user_id: this.$route.params.id,
        amount: 0,
        transaction_type_id: 0,
        transaction_reference: "",
        capturedby_id: this.$store.getters["auth/USERPROFILE"].system_usersid,
        startDate: null,
        endDate: null,
      },
      REVERSECASHBACKTRANSACTION: {
        taxiassociations_id: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
        systemuser_id: this.$route.params.id,
        cashbackid: "",
        capturedby_id: this.$store.getters["auth/USERPROFILE"].system_usersid,
      },
    };
  },
  beforeMount: function () {
    // this.clearAll();
    this.getSystemUsers(this);
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader,
    DropZoneControl,
    AudioVideoControl,
  },
  computed: {
    ...mapGetters({}),
    TOTALCASHUPBAL: function(){
      let sum = 0;
      this.MERCHANTTRANSACTIONS.forEach(function(item) {
        if(item._transaction_amount && item._transaction_typename === 'Purchase')
         sum += (parseFloat(item._transaction_amount));
      });

     return sum;
   },
    LoggedInUserFullName: function () {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    },
    currency() {
      return { [this.position]: this.currencySymbol };
    },
    // getHistory: function() {
    //   return context.GETDRIVEREMPLOYMENTHISTORY;
    // }
  },
  watch: {},
  methods: {
    ...mapActions([
      "getMerchantByUserId",
      "getTransactionTypes",
      "getMerchantTransactions",
    ]),
    loadLookUps(context) {
      
      context.$store
        .dispatch("lookups/init", {
          router: context.$router,
        })
        .then((data) => {
          console.log("loadLookUps found: ", data);
          
          context.GETALLTITLES = data.LOADEDTITLES;
          context.GETALLGENDERS = data.LOADEDGENDER;
        })
        .catch((error) => {
          console.log("suburbs error:", error);
        })
        .finally(() => {});
    },
 getSystemUsers: context => {
      context.$store
        .dispatch("systemusers/getall", {
          router: context.$router
        })
        .then(data => {
          console.log("System users Loaded: ", data);

          context.GETSYSTEMUSERS = data;

         

        })
        .catch(error => {
          console.log(error);
          context.$Notify.Error(error, "");
        }).finally(error => {
          $(".lisofmarshals").select2({
            multiple: false,
            placeholder: "Select A Agent",
            allowClear: false,
          });

           $(".lisofmarshals").on("change", function () {
            const context = $(document).data("context");
            context.CASNTRANSFERTRANSACTION.to_systemuser_id = $(".lisofmarshals option:selected").val();
            
          });

$("#advance-daterange span").html(
      moment()
        .subtract("days", 29)
        .format("MMMM D, YYYY") +
        " - " +
        moment().format("MMMM D, YYYY")
    );

        });
    },  
    transact: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          value.item.transaction_reference = document.getElementById(
            "clipboard-textarea"
          ).value;

          value.context.$wait.start("trasact");

          value.item.user_id = parseInt(value.item.user_id);
          value.context.$store
            .dispatch("prepaidservices/AddTransaction", {
              AddModel: value.item,
              router: value.router,
            })
            .then(() => {
              value.context.$wait.end("trasact");
              
              value.context.$Notify.Success(
                "Transaction Added Successfully!",
                ""
              );
              context.merchantTransactions(
                context,
                context.taxiassociationid,
                context.id
              );

            })
            .catch((error) => {
              value.context.$wait.end("trasact");
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    transactCashTransfer: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          
          value.context.$wait.start("transactCashTransfer");

          value.item.from_systemuser_id = parseInt(value.item.from_systemuser_id);
          value.item.to_systemuser_id = parseInt(value.item.to_systemuser_id);
          
          
          
          value.context.$store
            .dispatch("prepaidservices/AddTransactionCashTransfer", {
              AddModel: value.item,
              router: value.router,
            })
            .then(() => {
              value.context.$wait.end("transactCashTransfer");
              
              value.context.$Notify.Success(
                "Cash Transfer Was Successfully!",
                ""
              );
              context.merchantTransactions(
                context,
                context.taxiassociationid,
                context.id
              );

            })
            .catch((error) => {
              value.context.$wait.end("transactCashTransfer");
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    reverseCashBack: (context, item) => {
      context.$Notify.YesNo(false, { context, item }).then((value) => {
        if (value) {
          
          value.context.$wait.start("reverseCashBack"+ item._id);

          

          value.context.REVERSECASHBACKTRANSACTION.systemuser_id = parseInt(value.context.REVERSECASHBACKTRANSACTION.systemuser_id);
          value.context.REVERSECASHBACKTRANSACTION.capturedby_id = parseInt(value.context.REVERSECASHBACKTRANSACTION.capturedby_id);
          value.context.REVERSECASHBACKTRANSACTION.cashbackid = value.item._id;
          
          value.context.$store
            .dispatch("prepaidservices/reverseCashBackWeb", {
              AddModel: value.context.REVERSECASHBACKTRANSACTION,
              router: value.router,
            })
            .then(() => {
               value.context.$wait.end("reverseCashBack"+item._id);
              
              value.context.$Notify.Success(
                "CashBack Was Successfully Reserved!",
                ""
              );
              context.merchantTransactions(
                context,
                context.taxiassociationid,
                context.id
              );
            })
            .catch((error) => {
               value.context.$wait.end("reverseCashBack"+item._id);
              value.context.$Notify.Error(error.data[0].message, "");
            })
            .finally(() => {});
        }
      });
    },
    ShowControlPanel: (context) => {
      if(context.MERCHANTTRANSACTIONS.length >0 && !context.dataLoadedgrid)
          {

             var options = {
                    dom: "lBfrtip",
                    buttons: [
                      { extend: "copy", className: "btn-sm" },
                      { extend: "csv", className: "btn-sm" },
                      { extend: "excel", className: "btn-sm" },
                      { extend: "pdf", className: "btn-sm" },
                      { extend: "print", className: "btn-sm" }
                    ],
                    responsive: true,
                    autoFill: false,
                    colReorder: false,
                    keys: true,
                    rowReorder: false,
                    select: true
                  };

          $("#listoftransactions").DataTable(options);

          context.dataLoadedgrid = true;

          }
    },
    viewVoucher: (context, response) => {
      
      context.VOUCHERDETAILS = response.data;
      $("#modal-viewvoucher").modal("show");
    },

    getMerchantById: (context, id) => {
      context
        .getMerchantByUserId({
          id: id,
          router: context.$router,
        })
        .then((_rb) => {
          context.accountNumber = _rb[0].account_number;
          context.availableBalance = _rb[0].available_balance;
          context.firstname = _rb[0].firstname;
          context.surname = _rb[0].surname;
          context.id_number = _rb[0].id_number;
          context.mobile_number = _rb[0].merchant_mobile_number;
          context.username = _rb[0].username;
          context.title_id = _rb[0].title_id;
          context.gender_id = _rb[0].gender_id;
          context.taxiassociation_name = _rb[0].taxi_association_name;
          context.taxiass_abbr_name = _rb[0].taxi_abbr_name;
          context.title = _rb[0].title;
          context.CASHUPLIMIT = _rb[0].overdraft_limit;
        });
    },

    getTransactiontypes: (context) => {
      context
        .getTransactionTypes({
          router: context.$router,
        })
        .then((_rb) => {
          console.log("transaction types: ", _rb);
          context.TRANSACTIONTYPE = _rb;
        })
        .catch((error) => {
          console.log("transaction types errors: ", error);
        });
    },
    viewvoucher: (context, taxiassociationid, userid) => {
      context
        .getMerchantTransactions({
          router: context.$router,
          _getPagingModel: {
            skip: taxiassociationid,
            len: userid,
          },
        })
        .then((_rb) => {
          console.log("merchant transactions: ", _rb);
          context.MERCHANTTRANSACTIONS = _rb;
          if(context.MERCHANTTRANSACTIONS && context.MERCHANTTRANSACTIONS.length > 0)
           context.TOTALCOMMISSION = context.MERCHANTTRANSACTIONS[0]._totalcommission
        })
        .catch((error) => {
          console.log("transaction types errors: ", error);
        });
    },

    merchantTransactions: (context, taxiassociationid, userid) => {
      context.$wait.start("loading");
      context.dataLoaded = false;

      


    var payload = {
      taxiassociationsId: taxiassociationid,
      agentid: userid,
      startDate: context.$Toolkit.ConvertToServerDate(context.startDate),
      endDate: context.$Toolkit.ConvertToServerDate(context.endDate),
    };


      context
        .getMerchantTransactions({
          router: context.$router,
          payload
        })
        .then((_rb) => {
          console.log("merchant transactions: ", _rb);
          context.dataLoaded = true;
          context.MERCHANTTRANSACTIONS = _rb;
          if(context.MERCHANTTRANSACTIONS && context.MERCHANTTRANSACTIONS.length > 0)
           context.TOTALCOMMISSION = context.MERCHANTTRANSACTIONS[0]._totalcommission

           context.$wait.end("loading");
        })
        .catch((error) => {
          console.log("transaction types errors: ", error);
          context.$wait.end("loading");
        }).finally(() => {

          

        });

    },

    FILTERBYROLE: (context) => {
      $(".byrole").each(function () {
        if (
          context.USERROLES.length > 0 &&
          context.USERROLES.filter(
            (item) => item._code === $(this).attr("data-role")
          ).length > 0
        )
          $(this).removeClass("hide");
      });
    },
  },
  mounted: function () {
    this.getMerchantById(this.context, this.id);
    this.getTransactiontypes(this.context);

    this.loadLookUps(this.context);

    $(document).data("context",this);
    
    App.init();

    this.SYSTEMUSERPROFILE = this.$store.getters[
      "systemusers/GETUSERPROFILE"
    ][0];
    const contexttemp = this;
    this.$store
      .dispatch("systemusers/getProfilePicture", {
        id: this.id,
      })
      .then((result) => {
        contexttemp.PICTURE = result.data[0].fn_system_users_get_profilepicture
          ? result.data[0].fn_system_users_get_profilepicture.data
          : "/assets/img/user/user-13.jpg";
      })
      .catch((err) => {
        console.log(err);
      });

      

    $("#advance-daterange").daterangepicker(
      {
        format: "MM/DD/YYYY",
        startDate: moment().subtract(1, "days"),
        endDate: moment(),
        minDate: "01/01/2015",
        maxDate: "12/31/2050",
        dateLimit: { days: 60 },
        showDropdowns: true,
        showWeekNumbers: true,
        timePicker: false,
        timePickerIncrement: 1,
        timePicker12Hour: true,
        ranges: {
          Today: [moment(), moment()],
          Yesterday: [
            moment().subtract(1, "days"),
            moment().subtract(1, "days")
          ],
          "Last 7 Days": [moment().subtract(6, "days"), moment()],
          "Last 30 Days": [moment().subtract(29, "days"), moment()],
          "This Month": [moment().startOf("month"), moment().endOf("month")],
          "Last Month": [
            moment()
              .subtract(1, "month")
              .startOf("month"),
            moment()
              .subtract(1, "month")
              .endOf("month")
          ]
        },
        opens: "right",
        drops: "down",
        buttonClasses: ["btn", "btn-sm"],
        applyClass: "btn-primary",
        cancelClass: "btn-default",
        separator: " to ",
        locale: {
          applyLabel: "Submit",
          cancelLabel: "Cancel",
          fromLabel: "From",
          toLabel: "To",
          customRangeLabel: "Custom",
          daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ],
          firstDay: 1
        }
      },
      function(start, end, label) {
        

        const context = $(document).data("context");

        context.startDate = start.format("D MMMM YYYY");
        context.endDate = end.format("D MMMM YYYY");

        $("#advance-daterange span").html(
          start.format("D MMMM YYYY") + " - " + end.format("D MMMM YYYY")
        );

        

        context.merchantTransactions(context, context.taxiassociationid, context.id);
        
      }
    );

    this.startDate = $('#advance-daterange').data('daterangepicker').startDate.format("D MMMM YYYY");
    this.endDate = $('#advance-daterange').data('daterangepicker').endDate.format("D MMMM YYYY");
    this.merchantTransactions(this.context, this.taxiassociationid, this.id);


  },
  beforeDestroy: function () {},
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
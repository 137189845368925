<template>
  <div class="home">
    <!-- begin #page-container -->
    <!-- begin #header -->
    <headerComp
      :AddTaxiOperatorDropDownControl="true"
      :AddTopSearchControl="true"
      :AddTopNotificationControl="true"
      :isAdminUser="USERPROFILE.is_system_admin"
    />
    <!-- begin #header -->
    <!-- end page container -->

    <!-- begin #sidebar -->
    <sidebarComp
      :USERROLES="ROLES"
      :USERPROFILE="USERPROFILE"
      :ActiveSubMenu="'CashUp Details'"
      :ActiveMenu="'CashUp Details'"
    />
    <!-- end #sidebar -->

    <!-- begin #content -->
    <div id="content" class="content">
      <!-- begin page-header -->
      <headerpagebreadcrumb
        pageheaderTitle="CashUp Details"
        pageheaderdescription
        breadcrumbitem1="Home"
        breadcrumbitem2="Money"
        breadcrumbitem3="CashUp Details"
      />
      <!-- end page-header -->

      <!-- begin #Main Body Template -->

      <div class="row">
              <!-- begin col-3 -->
              <div class="col-lg-2 col-md-6">
                <div class="widget widget-stats bg-white text-inverse">
                  
                  <div class="stats-content">
                    <div class="stats-title">CASH BALANCE</div>
                    <div class="stats-number">{{ TOTALCASHUPBAL | newcurrencywithSymbol}}</div>
                  </div>
                </div>
              </div>
      </div>

      <!-- begin row -->

      <div class="row">
        <!-- begin col-12 -->
        <div class="col-lg-12">
          <!-- begin panel -->
          <div class="panel panel-primary">
            <!-- begin panel-heading -->
            <div class="panel-heading">
              <div class="panel-heading-btn">
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-default"
                  data-click="panel-expand"
                >
                  <i class="fa fa-expand"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-success"
                  data-click="panel-reload"
                >
                  <i class="fa fa-redo"></i>
                </a>
                <a
                  href="javascript:;"
                  class="btn btn-xs btn-icon btn-circle btn-warning"
                  data-click="panel-collapse"
                >
                  <i class="fa fa-minus"></i>
                </a>
              </div>

              <h4 class="panel-title">CashUp Details</h4>
            </div>
            <!-- end panel-heading -->

            <!-- begin panel-body -->
            <div class="panel-body">
              <!-- begin tab-content -->
              <div class="tab-content">
                
                <!-- begin tab-pane -->
                <table id="data-table-combine-payments" class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th ></th>
                      <th width="10%">Name</th>
                      <th >Amount</th>
                      <th >Captured By</th>
                      <th >Captured Date</th>
                      <th >Status</th>
                      <th class="text-nowrap hidden-print">Location</th>
                      <th class="text-nowrap hidden-print">Voice Note</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="(item,index) in GETCOLLECTORIDPAYMENTHISTORY"
                      v-bind:key="item._id"
                      :class="index & 1 ? 'odd gradeA' : 'even'"
                    >
                      <td width="1%" class="f-s-600 text-inverse">{{++index}}</td>
                      <td>{{item.entityname}}</td>
                      <td>{{item._amount | newcurrencywithSymbol}}</td>
                      <td>{{item.captured_name}}</td>
                      <td>{{item.transactiondate }}</td>
                      <td>{{item.receiptstatus}}</td>
                      <td class="with-btn valign-middle" nowrap>
                          <button
                          v-if="item.geolocation"
                            class="btn btn-sm btn-primary width-120 m-r-2"
                              @click="viewLocation(item.geolocation)"
                            >View Location</button>
            
                        </td>
                        <td class="valign-middle" nowrap>
                          <audio style="width: 100px;height: 20px"  v-if="item.hasvoicenotes" controls>
                          <source :src="item.voicenotes[0].data" type="audio/mpeg">
                              Your browser does not support the audio element.
                          </audio>
                        </td>
                    </tr>
                  </tbody>
                </table>
                <!-- end tab-pane -->
              </div>
              <!-- end tab-content -->
            </div>
            <!-- end panel-body -->
          </div>
          <!-- end panel -->
        </div>
        <!-- end col-12 -->
      </div>

      <!-- end row -->

      <!-- begin #Main Body Template -->
        <!-- #modal-message start -->
      <div class="modal fade" id="disputesModal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="text-center">
              <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
              <div class="height-450">
                    <div style="height: 100%;width: 100%;" id="mapid"></div>
              </div>
            </div>
            <div class="text-center m-b-5">
              <a href="javascript:;" class="btn btn-danger m-5 btn-lg" data-dismiss="modal">Close</a>
            </div>
          </div>
        </div>
      </div>
      <!-- #modal-message end -->

      <!-- end #content -->
    </div>

    <!-- begin scroll to top btn -->
    <pagescroller />
    <!-- end scroll to top btn -->
  </div>
</template>

<script>
// @ is an alias to /src
//import LoginComp from '@/components/LoginComp.vue'

import headerComp from "@/components/common/header.vue";
import headerpagebreadcrumb from "@/components/common/pageheader-breadcrumb.vue";
import pageloader from "@/components/common/pageloader.vue";
import pagescroller from "@/components/common/pagescroller.vue";
import sidebarComp from "@/components/common/sidebar.vue";

import { createNamespacedHelpers } from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers("payments");

export default {
  name: "ManageVehicles",
  data: function() {
    return {
      ROLES: this.$store.getters["auth/GETUSERROLES"],
      USERPROFILE: this.$store.getters["auth/USERPROFILE"],
      taxiassociationid: this.$store.getters["auth/ASSIGNEDTAXIASSOCIATION"].id,
      ISINTERNALSYSTEMUSER: this.$store.getters["auth/ISINTERNALSYSTEMUSER"],
      context: this,
      addedsuccess: undefined,
      errormessage: undefined,
      collectorid: this.$route.params.collectorid,
      startdate: this.$route.params.startdate,
      enddate: this.$route.params.enddate,
      activesession: this.$route.params.activesession,
      skip: 0,
      length: 10000,
      startDate: {},
      GETCOLLECTORIDPAYMENTHISTORY:[],
      endDate: {},
      paymentsloaded: false,
      arearsvehiclesloaded: false,
      arrearsoperatorsloaded: false, 
      maploaded: false,
      mymap:null,
      marker:null,
      tileLayer: null,
      dataloaded: false,
      dataloadedABC:true
    };
  },
  beforeCreate: function() {
    document.body.classList.remove("bg-white");
  },
  components: {
    headerpagebreadcrumb,
    headerComp,
    sidebarComp,
    pagescroller,
    pageloader
  },
  computed: {
    ...mapGetters({
    }),
    TOTALCASHUPBAL: function(){
      let sum = 0;
      this.GETCOLLECTORIDPAYMENTHISTORY.forEach(function(item) {
        if(item._amount)
         sum += (parseFloat(item._amount));
      });

     return sum;
   },
    LoggedInUserFullName: function() {
      return this.USERPROFILE.first_name + " " + this.USERPROFILE.surname;
    }
  },
  methods: {
    ...mapActions(["generatepaymentByCollectorIdHistory"]),
    viewLocation: function(geolocation) {
      
      $("#disputesModal").modal("show");

       
      
      if(!this.maploaded)
      {

        this.maploaded = true;

        

            setTimeout(function(context){

            context.mymap = L.map('mapid').setView([geolocation.latitude,geolocation.longitude], 16);

              context.tileLayer = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFueW93YSIsImEiOiJjbHlvczVqdjUwaGNjMmpzZmFnN2h4djZkIn0.ZjB9zzv5VjI1EsQY4VRsUA', {
                maxZoom: 18,
                attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, ' +
                  'Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                id: 'mapbox/streets-v11',
                tileSize: 512,
                zoomOffset: -1
              }).addTo(context.mymap);

              

                context.tileLayer.on("load",function() { 

                if(!context.marker)
                  context.marker = L.marker([geolocation.latitude, geolocation.longitude]).addTo(context.mymap);

                });


              }, 600,this);
        
      }
      else
      {

        this.marker.setLatLng(L.latLng(geolocation.latitude, geolocation.longitude))
        this.mymap.panTo(L.latLng(geolocation.latitude, geolocation.longitude));

      }


      
    },
  },
  beforeMount: function() {},
  mounted: function() {
    //this.getbyid(this.context, this.taxiassociationid);

    App.init();

    const context = this;
    const inprogress = true;

    

    let _enddate = context.$Toolkit.ConvertToServerDate(new Date());

    

    if(context.activesession === 'false')
    {
      _enddate = context.enddate;
      inprogress = false;
    }

    var payload = {
      taxiassociationsId: context.taxiassociationid,
      collectorid: context.collectorid,
      startDate: context.startdate,
      endDate: _enddate,
      inprogress: inprogress,
    };

    const router = context.$router;

    context
      .generatepaymentByCollectorIdHistory({ router, payload })
      .then(parsedResultOP => {
        if (parsedResultOP.status) {
          if (parsedResultOP.data.length > 0) {
            if ($("#data-table-combine-payments").length !== 0) {
             

              if (!context.paymentsloaded)
              {
                context.GETCOLLECTORIDPAYMENTHISTORY = parsedResultOP.data;
                
              }

              context.context.paymentsloaded = true;
            }
          }
        }
      }).finally(() => {
         var options = {
                dom: "lBfrtip",
                buttons: [
                  { extend: "copy", className: "btn-sm" },
                  { extend: "csv", className: "btn-sm" },
                  { extend: "excel", className: "btn-sm" },
                  { extend: "pdf", className: "btn-sm" },
                  { extend: "print", className: "btn-sm" }
                ],
                responsive: false,
                autoFill: false,
                colReorder: false,
                keys: false,
                rowReorder: false,
                select: false
              };

              // if ($(window).width() <= 767) {
              //   options.rowReorder = false;
              //   options.colReorder = false;
              //   options.autoFill = false;
              // }

              $("#data-table-combine-payments").DataTable(options);
      
      });
  }
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>

